import React, {useEffect, useState} from 'react';
import ExpertComponent from './ExpertComponent';
import PlanningComponent from './PlanningComponent';
import {getPlanning} from '../Services/PlaningService';
import FooterBar from "./Pages/FooterBar";
import {getExpertsByService} from "../Services/ExpertsByService";
import {useParams} from "react-router-dom";
import HeaderSimple from "./Pages/HeaderSimple";
import {getServiceDetails} from "../Services/ServiceDetails";


export default function ExpertsByService() {
    const [experts, setExperts] = useState([]);
    const [startDateMap, setStartDateMap] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [listExpertService, setListExpertService] = useState([]);
    const [planningData, setPlanningData] = useState([]); // Nouvelle state pour les données du planning
    const [cachedPlanningData, setCachedPlanningData] = useState({});
    const [cachedExpertsData, setCachedExpertsData] = useState({}); // Ajout de l'état pour les experts en cache
    const {serviceId} = useParams(); // Récupère l'ID du service depuis l'URL
    const [serviceDetails, setServiceDetails] = useState(null); // État pour les détails du service
    const [page, setPage] = useState(0);
    const [size] = useState(2); // Nombre d'experts par page
    const [hasMore, setHasMore] = useState(true);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const cachedExperts = cachedExpertsData['experts'];
                const expertsData = cachedExperts ? cachedExperts : await getExpertsByService(serviceId, page, size);

                if (expertsData.content.length < size) {
                    setHasMore(false);
                }

                // Récupérer les détails du service par ID
                const service = await getServiceDetails(serviceId);
                setServiceDetails(service);

                if (!cachedExperts) {
                    setCachedExpertsData({ experts: expertsData });
                }

                const startDateMapData = {};
                const planningPromises = [];

                expertsData.content.forEach(expert => {
                    const lastMonday = getLastMondayForExpert(expert.idExpert);
                    const formattedDate = new Date(`${lastMonday.getFullYear()}-${String(lastMonday.getMonth() + 1).padStart(2, '0')}-${String(lastMonday.getDate()).padStart(2, '0')}`);
                    startDateMapData[expert.idExpert] = formattedDate;
                    planningPromises.push(getPlanning(formattedDate, expert.idExpert));
                });

                const planningData = await Promise.all(planningPromises);

                // Mettre à jour le cache
                setCachedPlanningData(prevState => ({
                    ...prevState,
                    [JSON.stringify(startDateMapData)]: planningData
                }));

                setPlanningData(planningData);
                setExperts(expertsData.content); // Mettre à jour les experts ici
                setStartDateMap(startDateMapData);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchData();
    }, [serviceId]);



    const getLastMonday = (date) => {
        const dayOfWeek = date.getDay();
        const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    };

    const getLastMondayForExpert = (expertId) => {
        const date = new Date();
        const dayOfWeek = date.getDay();
        const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
        return new Date(date.setDate(diff));
    };

    const handlePrevWeek = (expertId) => {
        const newStartDate = new Date(startDateMap[expertId] || getLastMondayForExpert(expertId));
        newStartDate.setDate(newStartDate.getDate() - 7);

        if (newStartDate < new Date()) {
            setStartDateMap(prevState => ({
                ...prevState,
                [expertId]: getLastMondayForExpert(expertId),
            }));
            return;
        }

        setStartDateMap(prevState => ({
            ...prevState,
            [expertId]: newStartDate,
        }));
    };


    const handleNextWeek = (expertId) => {
        const currentStartDate = new Date(startDateMap[expertId] || getLastMondayForExpert(expertId));
        const newStartDate = new Date(currentStartDate);
        newStartDate.setDate(currentStartDate.getDate() + 7);

        // Vérifier si la nouvelle date dépasse la limite de 4 semaines
        const maxDate = new Date(getLastMondayForExpert(expertId));
        maxDate.setDate(maxDate.getDate() + 27); // Limite de 4 semaines (28 jours)

        if (newStartDate > maxDate) {
            return; // Si on dépasse les 4 semaines, on ne fait rien
        }

        setStartDateMap(prevState => ({
            ...prevState,
            [expertId]: newStartDate,
        }));
    };

    const renderDays = (startDate) => {
        const days = [];
        const startDay = new Date(startDate);
        startDay.setDate(startDay.getDate() - startDay.getDay() + (startDay.getDay() === 0 ? -6 : 1));

        for (let i = 0; i < 7; i++) {
            const date = new Date(startDay);
            date.setDate(startDay.getDate() + i);
            const day = date.toLocaleString('default', {weekday: 'short'}).toLowerCase();
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;

            days.push(
                <div key={i}
                     className="flex items-center justify-center flex-col w-11 md:mx-[17px] md:w-[38px] text-sm font-medium">
                    <div className={"Date "}>
                        <div className={`text-center ${day === 'sam.' || day === 'dim.' ? 'text-red-500' : ''}`}>
                            {day.charAt(0).toUpperCase() + day.slice(1)}
                        </div>
                        <div className="text-xs">{formattedDate}</div>
                    </div>
                </div>

            );
        }
        return days;
    };

    const [displayCount, setDisplayCount] = useState(2); // Nombre d'experts à afficher initialement

    const handleLoadMore = async () => {
        try {
            const nextPage = page + 1; // Utilise nextPage au lieu de page ici
            const newExperts = await getExpertsByService(serviceId, nextPage, size); // Utilise nextPage au lieu de page

            if (newExperts.content.length < size) {
                setHasMore(false);
            }

            const startDateMapData = { ...startDateMap };
            const planningPromises = [];

            newExperts.content.forEach(expert => {
                const lastMonday = getLastMondayForExpert(expert.idExpert);
                const formattedDate = new Date(`${lastMonday.getFullYear()}-${String(lastMonday.getMonth() + 1).padStart(2, '0')}-${String(lastMonday.getDate()).padStart(2, '0')}`);
                startDateMapData[expert.idExpert] = formattedDate;
                planningPromises.push(getPlanning(formattedDate, expert.idExpert));
            });

            const newPlanningData = await Promise.all(planningPromises);

            setPlanningData(prevData => [...prevData, ...newPlanningData]);
            setExperts(prevExperts => [...prevExperts, ...newExperts.content]);
            setStartDateMap(startDateMapData);
            setPage(nextPage); // Met à jour la page avec nextPage
        } catch (err) {
            setError(err.message);
        }
    };



    return (
        <nav className="m-auto ">
            <HeaderSimple
                mainTitle="Les experts en "
                subTitle={serviceDetails ? serviceDetails.nomService : 'Chargement...'}
            ></HeaderSimple>
            <div>
                {/*{loading ? (
                    <div className="relative p-5 mt-5">
                        <div className="absolute inset-0 bg-white bg-opacity-60 flex items-center justify-center">
                            <div className="flex items-center">
                                <span className="text-md font-poppins mr-4">Chargement...</span>
                                <svg className="animate-spin h-8 w-8 text-gray-800" xmlns="http://www.w3.org/2000/svg"
                                     fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                            strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor"
                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                ) : (*/}
                    <>
                        <div className="middle-content-exp m-auto">
                            <div className="p-1">
                                {experts.length === 0 ? (
                                    <div
                                        className="text-center text-gray-700 font-medium mt-6 rounded-lg p-4">
                                        <p className="text-lg font-semibold">Aucun expert disponible pour ce
                                                                             service.</p>
                                        <p className="text-sm text-gray-500 mt-1">Veuillez vérifier plus tard ou essayer
                                                                                  un autre service.</p>
                                    </div>
                                ) : (
                                    experts.map((expert, index) => (
                                        <div key={expert.idExpert}
                                             className="mx-auto my-10 md:my-5 md:border-0 border-y-[6px] pt-4 p-0.5 border-green-600 md:p-0 rounded-2xl">
                                            <div className="flex flex-col md:flex-row items-center md:mb-5 justify-center">
                                                <ExpertComponent expert={expert}/>
                                                <PlanningComponent
                                                    startDate={startDateMap[expert.idExpert]}
                                                    handlePrevWeek={() => handlePrevWeek(expert.idExpert)}
                                                    handleNextWeek={() => handleNextWeek(expert.idExpert)}
                                                    renderDays={() => renderDays(startDateMap[expert.idExpert])}
                                                    expert={expert}
                                                    expertsData={experts}
                                                    listExpertService={listExpertService}
                                                    planningData={planningData.find(data => data.idExpert === expert.idExpert)}
                                                    serviceId={serviceId}
                                                />
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                        </div>
                        {hasMore && (
                            <div className="flex justify-center mt-4">
                                <button
                                    className="bg-teal-950 font-poppins hover:bg-teal-700 text-white font-semibold py-2 px-4 rounded"
                                    onClick={handleLoadMore}>
                                    Charger plus
                                </button>
                            </div>
                        )}
                    </>
                {/*)}*/}
            </div>

            <FooterBar></FooterBar>
        </nav>
    );
}
