import apiClient from './apiService';

export const getDomaineService = async () => {
    try {
        const response = await apiClient.get('/domaines/list');
        return response.data;
    } catch (error) {
        throw error;
    }
};
//react query