import React from 'react';
import photoCongrat from "../../images/icon_Congratulation.webp";
import Confirmation from '../Confirmation';

export default function CongratulationUrlReset() {
    return (
        <Confirmation
            title="Félicitations !!"
            primaryMessage="Le lien de réinitialisation a été envoyé avec succès."
            secondaryMessage="Veuillez vérifier votre boîte de réception pour continuer le processus de réinitialisation de votre mot de passe."
            tertiaryMessage="Si vous ne recevez pas l'e-mail, pensez à vérifier votre dossier spam ou contactez le support."
            imageSrc={photoCongrat}
            altText="icon Validate"
        />
    );
}