import React, {useEffect, useState} from 'react';
import logoHbm from '../../images/Logo_Les-Experts_FNL_V-09.webp'
import {Link, Outlet, useNavigate} from "react-router-dom";
import useSignOut from "react-auth-kit/hooks/useSignOut";

export default function Navbar() {
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();

    const signOut = useSignOut(); // Hook pour gérer la déconnexion

    const firstName = localStorage.getItem('userFirstName');
    const lastName = localStorage.getItem('userLastName');
    const tel = localStorage.getItem('userTel');
    const email = localStorage.getItem('userEmail');
    const role = localStorage.getItem('role');

    useEffect(() => {
        saveCurrentRoute();
    }, []);
    const handleButtonClickLogin = () => {
        navigate('/login');
    };
    const handleButtonClickRegister = () => {
        navigate('/register');
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    // État pour gérer l'ouverture du menu du profil
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);

    // Fonction pour basculer le menu du profil
    const toggleProfileMenu = () => {
        setIsProfileMenuOpen(!isProfileMenuOpen);
    };

    // Fonction pour rediriger l'utilisateur
    const redirectTo = (url) => {
        window.location.href = url;
    };

    // Enregistrer la route actuelle dans localStorage
    const saveCurrentRoute = () => {
        localStorage.setItem('lastRoute', window.location.pathname); // Save the current route
    };

    // Vérifier si la session est expirée
    const isSessionExpired = () => {
        const sessionExpireAtString = localStorage.getItem('session_expire_at');
        if (!sessionExpireAtString) {
            return false;
        }
        const sessionExpireAt = new Date(sessionExpireAtString);
        const now = new Date();
        return now > sessionExpireAt;
    };

    // Gérer la session expirée
    const handleSession = () => {
        if (isSessionExpired()) {
            signOut(); // Déconnexion de l'utilisateur
            window.localStorage.removeItem("session_expire_at"); // Supprimer l'expiration de la session
            window.localStorage.removeItem("jwt_token"); // Supprimer le token JWT
            window.localStorage.removeItem('userFirstName'); // Supprimer les informations utilisateur
            window.localStorage.removeItem('userLastName');
            window.localStorage.removeItem('userTel');
            window.localStorage.removeItem('userEmail');
            window.localStorage.removeItem('role');
            navigate('/');
        }
    };

    // Utilisation de useEffect pour vérifier la session dès que le composant est monté
    useEffect(() => {
        const interval = setInterval(() => {
            console.log('check-expiration', isSessionExpired())
            handleSession()
        }, 1000);
        return () => clearInterval(interval);
    }, []);



    // Gestion de la déconnexion
    // Déconnexion manuelle
    const handleLogout = () => {
        const lastRoute = localStorage.getItem('lastRoute');
        signOut(); // Déconnecter l'utilisateur
        window.localStorage.removeItem('session_expire_at'); // Supprimer l'expiration de la session
        window.localStorage.removeItem('jwt_token'); // Supprimer le token JWT
        window.localStorage.removeItem('userFirstName'); // Supprimer les informations utilisateur
        window.localStorage.removeItem('userLastName');
        window.localStorage.removeItem('userTel');
        window.localStorage.removeItem('userEmail');
        window.localStorage.removeItem('role');
        //navigate(lastRoute || '/'); // Rediriger vers la page de connexion
        navigate('/'); // Rediriger vers la page de connexion
    };

    return (
        <>
            <nav className="navbar flex justify-center bg-gray-700 bg-opacity-0 font-montserrat font-medium">
                <div className="flex flex-row w-3/4 px-5 py-5 justify-between items-center">
                    <div className="menu-left flex flex-row space-x-4 pr-4 items-center">
                        <a href="/"><img src={logoHbm} alt="MyLogo" className="logo mr-12 w-[110px]"/></a>
                        <div className="hidden lg:flex space-x-6 text-white items-center">
                            <Link to='/'
                                  className={`hover:text-green-300 ${window.location.pathname === '/' ? 'text-green-600' : ''}`}
                                  aria-current={window.location.pathname === '/' ? 'page' : undefined}>Accueil</Link>
                            <Link to='/NosServices'
                                  className={`hover:text-green-300 ${window.location.pathname.startsWith('/NosServices') ? 'text-green-600' : ''}`}
                                  aria-current={window.location.pathname.startsWith('/NosServices') ? 'page' : undefined}>Nos
                                                                                                                          services</Link>
                            <Link to='/NosExperts'
                                  className={`hover:text-green-300 ${window.location.pathname.startsWith('/NosExperts') || window.location.pathname.startsWith('/expertSelected') ? 'text-green-600' : ''}`}
                                  aria-current={window.location.pathname.startsWith('/NosExperts') || window.location.pathname.startsWith('/expertSelected') ? 'page' : undefined}>Nos
                                                                                                                                                                                   experts</Link>
                            <Link to='/Contact'
                                  className={`hover:text-green-300 ${window.location.pathname === '/Contact' ? 'text-green-600' : ''}`}
                                  aria-current={window.location.pathname === '/Contact' ? 'page' : undefined}>Contact</Link>
                        </div>
                    </div>
                    {/* Menu de navigation */}
                    <div className="space-x-4 hidden lg:flex">
                        {firstName && lastName ? (
                            <div className="relative">
                                {/* Nom et prénom affichés */}
                                <button
                                    onClick={toggleMenu}
                                    className="text-white font-medium flex items-center space-x-2 focus:outline-none"
                                    aria-haspopup="true"
                                    aria-expanded={isOpen}
                                >
                                    <span>Bonjour, {firstName} {lastName}</span>
                                    <svg
                                        className={`w-5 h-5 transition-transform transform ${isOpen ? "rotate-180" : "rotate-0"}`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path fillRule="evenodd"
                                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                              clipRule="evenodd"/>
                                    </svg>
                                </button>

                                {/* Menu déroulant avec animation de transition */}
                                {isOpen && (
                                    <div
                                        className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-2 transition duration-200 ease-in-out transform origin-top-right"
                                    >
                                        {role === 'EXPERT' ? (
                                            <>
                                                <a href="/planningUpdate"
                                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Planning</a>
                                                <a href="/planning"
                                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Historique</a>
                                                {/*<a href="/settings"
                                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Paramètres</a>*/}
                                                <a href="/changePassword"
                                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Modifier mot de passe</a>
                                            </>
                                        ) : role === 'CLIENT' ? (
                                            <>
                                                <a href="/planningClient"
                                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Mes
                                                                                                               rendez-vous</a>
                                                {/*<a href="/settings"
                                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Paramètres</a>*/}
                                                <a href="/changePassword"
                                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Modifier
                                                                                                               mot de
                                                                                                               passe</a>
                                            </>
                                        ) : role === 'ADMIN' ? (
                                            <>
                                                <a href="/dashboard"
                                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Dashboard</a>
                                                <a href="/manage-users"
                                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Gestion
                                                                                                               des
                                                                                                               utilisateurs</a>
                                                {/*<a href="/settings"
                                                   className="block px-4 py-2 text-gray-800 hover:bg-gray-100">Paramètres</a>*/}
                                            </>
                                        ) : (
                                            <p className="px-4 py-2 text-gray-800">Rôle non reconnu</p>
                                        )}

                                        <button
                                            onClick={handleLogout}
                                            className="block w-full text-left px-4 py-2 text-gray-800 hover:bg-gray-100"
                                        >
                                            Déconnexion
                                        </button>
                                    </div>
                                )}
                            </div>

                        ) : (
                            <div className="hidden lg:flex items-center space-x-4">
                                <button className="text-white hover:text-green-300"
                                        onClick={handleButtonClickRegister}>S'inscrire
                                </button>
                                <button
                                    className="rounded-3xl bg-white hover:text-white hover:bg-green-700 text-black px-4 py-2"
                                    onClick={handleButtonClickLogin}>Se connecter
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="lg:hidden flex items-center">
                        <button onClick={toggleMenu} className="text-white">
                            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                      d="M4 6h16M4 12h16m-7 6h7"></path>
                            </svg>
                        </button>
                    </div>
                    {/* Menu mobile */}
                    {isOpen && (
                        <div
                            className="absolute top-16 left-0 w-full bg-neutral-800 bg-opacity-100 rounded-3xl p-5 flex flex-col space-y-4 lg:hidden z-50">
                            <Link to='/'
                                  className={`hover:text-green-300 ${window.location.pathname === '/' ? 'text-green-600' : ''}`}
                                  aria-current={window.location.pathname === '/' ? 'page' : undefined}>Accueil</Link>
                            <Link to='/NosServices'
                                  className={`hover:text-green-300 ${window.location.pathname.startsWith('/NosServices') ? 'text-green-600' : ''}`}
                                  aria-current={window.location.pathname.startsWith('/NosServices') ? 'page' : undefined}>Nos
                                                                                                                          services</Link>
                            <Link to='/NosExperts'
                                  className={`hover:text-green-300 ${window.location.pathname.startsWith('/NosExperts') ? 'text-green-600' : ''}`}
                                  aria-current={window.location.pathname.startsWith('/NosExperts') ? 'page' : undefined}>Nos
                                                                                                                         experts</Link>
                            <Link to='/Contact'
                                  className={`hover:text-green-300 ${window.location.pathname === '/Contact' ? 'text-green-600' : ''}`}
                                  aria-current={window.location.pathname === '/Contact' ? 'page' : undefined}>Contact</Link>

                            {/* Accordion menu pour le profil */}
                            <div className="accordion-item">
                                {firstName && lastName ? (
                                    <div>
                                        <button
                                            onClick={toggleProfileMenu} // pour gérer l'affichage/masquage du sous-menu
                                            className="text-white font-medium flex items-center justify-between w-full py-2 focus:outline-none"
                                            aria-expanded={isProfileMenuOpen}
                                        >
                                            Bonjour, {firstName} {lastName}
                                            <svg
                                                className={`w-5 h-5 transition-transform transform ${isProfileMenuOpen ? "rotate-180" : "rotate-0"}`}
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                                fill="currentColor"
                                            >
                                                <path fillRule="evenodd"
                                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                      clipRule="evenodd"/>
                                            </svg>
                                        </button>

                                        {isProfileMenuOpen && (
                                            <div className="ml-4 space-y-2">
                                                {role === 'EXPERT' ? (
                                                    <>
                                                        <a href="/planningUpdate"
                                                           className="block text-gray-300 hover:bg-gray-700 p-2">Planning</a>
                                                        <a href="/planning"
                                                           className="block text-gray-300 hover:bg-gray-700 p-2">Historique</a>
                                                        {/*<a href="/settings"
                                                           className="block text-gray-300 hover:bg-gray-700 p-2">Paramètres</a>*/}
                                                    </>
                                                ) : role === 'CLIENT' ? (
                                                    <>
                                                        <a href="/planningClient"
                                                           className="block text-gray-300 hover:bg-gray-700 p-2">Mes
                                                                                                                 rendez-vous</a>
                                                        {/*<a href="/settings"
                                                           className="block text-gray-300 hover:bg-gray-700 p-2">Paramètres</a>*/}
                                                        <a href="/changePassword"
                                                           className="block text-gray-300 hover:bg-gray-700 p-2">Modifier
                                                                                                                       mot
                                                                                                                       de
                                                                                                                       passe</a>
                                                    </>
                                                ) : role === 'USER' ? (
                                                    <>
                                                        {/*<a href="/settings"
                                                           className="block text-gray-300 hover:bg-gray-700 p-2">Paramètres</a>*/}
                                                    </>
                                                ) : role === 'ADMIN' ? (
                                                    <>
                                                        <a href="/dashboard"
                                                           className="block text-gray-300 hover:bg-gray-700 p-2">Dashboard</a>
                                                        <a href="/manage-users"
                                                           className="block text-gray-300 hover:bg-gray-700 p-2">Gestion
                                                                                                                 des
                                                                                                                 utilisateurs</a>
                                                        {/*<a href="/settings"
                                                           className="block text-gray-300 hover:bg-gray-700 p-2">Paramètres</a>*/}
                                                    </>
                                                ) : (
                                                    <p className="block text-gray-300 p-2">Rôle non reconnu</p>
                                                )}
                                                <button
                                                    onClick={handleLogout}
                                                    className="block text-gray-300 hover:bg-gray-700 w-full text-left p-2"
                                                >
                                                    Déconnexion
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <div className={"flex flex-col space-y-4"}>
                                        <button className="text-white hover:text-green-300"
                                                onClick={handleButtonClickRegister}>S'inscrire
                                        </button>
                                        <button
                                            className="rounded-3xl bg-white hover:text-white hover:bg-green-700 text-black px-4 py-2"
                                            onClick={handleButtonClickLogin}>Se connecter
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </nav>


            <div>
                <Outlet></Outlet>
            </div>
        </>


    );
}

