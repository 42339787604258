import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, Switch, BrowserRouter} from 'react-router-dom';

import ExpertsServices_v3 from "./components/ExpertsServices_v3";
import DetailsProspect from "./components/DetailsProspect";
import Confirmation from "./components/Confirmation";
import RefusProspect from "./components/RefusProspect";
import RefusExpert from "./components/RefusExpert";
import ValideRefusProspect from "./components/ValideRefusProspect";
import ValideRefusExpert from "./components/ValideRefusExpert";
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CongratulationPage from "./components/ConfirmationPages/CongratulationPage";
import SorryPage from "./components/ConfirmationPages/SorryPage";
import RefuseDemandePage from "./components/ConfirmationPages/RefuseDemandePage";
import ValidateDemandePage from "./components/ConfirmationPages/ValidateDemandePage";
import Navbar from "./components/Pages/Navbar";
import Header from "./components/Pages/Header";
import Accueil from "./components/Pages/Accueil";
import ExpertsByService from "./components/ExpertsByService";
import ExpertSelectedId from "./components/ExpertSelectedId";
import ScrollToTop from "./components/Pages/ScrollToTop";
import PageNotFound from "./components/Pages/PageNotFound";
import PageNosServices from "./components/Pages/PageNosServices";
import PageNosExperts from "./components/Pages/PageNosExperts";
import Contact from "./components/Pages/Contact";
import CongratulationPageContact from "./components/ConfirmationPages/CongratulationPageContact";
import PageMeet from "./components/Pages/PageMeet";
import JitsiMeetComponent from "./components/Pages/JitsiMeetComponent";
import PageListePlanning from "./components/Pages/PageListePlanning";
import PageListePlanningUpdate from "./components/Pages/PageListePlanningUpdate";
import PlanningUpdateComponent from "./components/PlanningUpdateComponent";
import ClientFeedback from "./components/ClientFeedback";
import ExpertFeedback from "./components/ExpertFeedback";
import LoginPage from "./components/LoginPage";
import RegisterPage from "./components/RegisterPage";
import RequireAuth from "./components/RequireAuth";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import RequireRole from "./components/RequireRole";
import Unauthorized from "./components/Unauthorized";
import ChangePassword from "./components/ChangePassword";
import ChangePasswordClt from "./components/ChangePasswordClt";
import PageListePlanningClt from "./components/Pages/PageListePlanningClt";
import CongratulationChangePassword from "./components/ConfirmationPages/CongratulationChangePassword";
import ResetPassword from "./components/ResetPassword";
import PasswordResetPage from "./components/PasswordResetPage";
import CongratulationUrlReset from "./components/ConfirmationPages/CongratulationUrlReset";
import PaymentSuccessful from "./components/PaymentSuccessful";
import PaymentFailed from "./components/PaymentFailed";
import { Helmet } from 'react-helmet';

export default function App() {

    const signOut = useSignOut()
    function redirectTo(url) { window.location.href = url; }
    const isSessionExpired = () => {
        const sessionExpireAtString = localStorage.getItem('session_expire_at');
        if (!sessionExpireAtString) {
            return false;
        }
        const sessionExpireAt = new Date(sessionExpireAtString);
        const now = new Date();
        return now > sessionExpireAt;
    }
    const handleSession = () => {
        if (isSessionExpired()) {
            signOut()
            window.localStorage.setItem("lastRoute",window.location.pathname)
            window.localStorage.removeItem("session_expire_at")
            window.localStorage.removeItem('userFirstName'); // Supprimer les informations utilisateur
            window.localStorage.removeItem('userLastName');
            window.localStorage.removeItem('userEmail');
            window.localStorage.removeItem('role');
            redirectTo("/login")
        }
    }
    useEffect(() => {
        const interval = setInterval(() => {
            console.log('check-expiration', isSessionExpired())
            handleSession()
        }, 1000);
        return () => clearInterval(interval);
    }, []);

  return (
    <BrowserRouter>
        <ScrollToTop />
        <Routes>
            <Route path="/" >
                <Route index element={<Accueil />} />
                <Route path="NosServices" element={<PageNosServices />} />
                <Route path="NosExperts" element={<PageNosExperts />} />
                <Route path="Contact" element={<Contact/>} />
                <Route path="*" element={<PageNotFound />} />
            </Route>

            <Route path="/service/:serviceId" element={<><Helmet><title>Expert - Les-experts.ma</title></Helmet><ExpertsByService /></>} />
            <Route path="/expertSelected/:ids" element={<><Helmet><title>Expert - Les-experts.ma</title></Helmet><ExpertSelectedId /></>} />

            <Route path="/home" element={<ExpertsServices_v3 />} />
            <Route path="/experts/:serviceId" element={<ExpertsByService />} />
            <Route path="/test2" element={<Accueil />} />
            <Route path="/meet/:id" element={<PageMeet />} />
            <Route path="/meet2" element={<JitsiMeetComponent />} />
            <Route path="/test" element={<Navbar />} />
            <Route path="/test1" element={<Header />} />
            <Route path="/Accueil" element={<Accueil />} />
            <Route path="/DetailsProspect" element={<><Helmet><title>Formulaire de réservation - Les-experts.ma</title></Helmet><DetailsProspect /></>} />
            <Route path="/congratulationPage" element={<><Helmet><title>Congratulation - Les-experts.ma</title></Helmet><CongratulationPage/></>} />
            <Route path="/congratulationPageContact" element={<><Helmet><title>Congratulation contact - Les-experts.ma</title></Helmet><CongratulationPageContact /></>} />
            <Route path="/sorryPage" element={<><Helmet><title>Sorry - Les-experts.ma</title></Helmet><SorryPage /></>} />
            <Route path="/refuseDemandePage" element={<><Helmet><title>Demande refusée - Les-experts.ma</title></Helmet><RefuseDemandePage /></>} />
            <Route path="/validateDemandePage" element={<><Helmet><title>Demande validée - Les-experts.ma</title></Helmet><ValidateDemandePage /></>} />
            <Route path="/CongChangePassword" element={<><Helmet><title>Changement de mot de passe réussi - Les-experts.ma</title></Helmet><CongratulationChangePassword /></>} />
            <Route path="/refusProspect/:id" element={<><Helmet><title>Refus du prospect - Les-experts.ma</title></Helmet><RefusProspect /></>} />
            <Route path="/refusExpert/:id" element={<><Helmet><title>Refus de l'expert - Les-experts.ma</title></Helmet><RefusExpert /></>} />
            <Route path="/valideRefusProspect/:id" element={<><Helmet><title>Validation ou refus du prospect - Les-experts.ma</title></Helmet><ValideRefusProspect /></>} />
            <Route path="/valideRefusExpert/:id" element={<><Helmet><title>Validation ou refus de l'expert - Les-experts.ma</title></Helmet><ValideRefusExpert /></>} />
            <Route path="/planning" element={
                <RequireAuth>
                    <RequireRole allowedRoles={['EXPERT']}>
                        <PageListePlanning />
                    </RequireRole>
                </RequireAuth>
            } />
            <Route path="/planningClient" element={
                <RequireAuth>
                    <RequireRole allowedRoles={['CLIENT']}>
                        <PageListePlanningClt />
                    </RequireRole>
                </RequireAuth>
            } />
            <Route path="/planningDispo" element={
                <PageListePlanningUpdate />
            } />
            <Route path="/planningUpdate" element={
                <RequireAuth>
                    <RequireRole allowedRoles={['EXPERT']}>
                        <PlanningUpdateComponent />
                    </RequireRole>
                </RequireAuth>
            } />
            <Route path="/AvisExpert/:id" element={<><Helmet><title>Avis de l'Expert - Les-experts.ma</title></Helmet><ExpertFeedback /></>} />
            <Route path="/AvisClient/:id" element={<><Helmet><title>Avis du Client - Les-experts.ma</title></Helmet><ClientFeedback /></>} />
            <Route path="/login" element={<><Helmet><title>Connexion - Les-experts.ma</title></Helmet><LoginPage /></>} />
            <Route path="/register" element={<><Helmet><title>Inscription - Les-experts.ma</title></Helmet><RegisterPage /></>} />
            <Route path="/resetPassword" element={<><Helmet><title>Réinitialiser le mot de passe - Les-experts.ma</title></Helmet><ResetPassword /></>} />
            <Route path="/passwordResetPage" element={<><Helmet><title>Page de réinitialisation du mot de passe - Les-experts.ma</title></Helmet><PasswordResetPage /></>} />
            <Route path="/changePassword" element={<><Helmet><title>Changer le mot de passe - Les-experts.ma</title></Helmet><ChangePassword /></>} />
            <Route path="/changePasswordClt/:id" element={<><Helmet><title>Changer le mot de passe du client - Les-experts.ma</title></Helmet><ChangePasswordClt /></>} />
            <Route path="/unauthorized" element={<><Helmet><title>Accès non autorisé - Les-experts.ma</title></Helmet><Unauthorized /></>} />
            <Route path="/congratulationUrlReset" element={<><Helmet><title>Réinitialisation réussie - Les-experts.ma</title></Helmet><CongratulationUrlReset /></>} />

            {/*<Route path="/successful" element={<PaymentSuccessful />} />*/}
            <Route path="/failed" element={<><Helmet><title>Échec du paiement - Les-experts.ma</title></Helmet><PaymentFailed /></>} />
            {/*<Route path="/failed">
                <Route
                    element={<React.Suspense
                        fallback={<div role="status" className="flex items-center justify-center h-[50vh] w-full">
                            <svg aria-hidden="true" className="w-8 h-8 text-third animate-spin  fill-gray-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="sr-only text-white">Loading...</span>
                        </div>}>
                        <PaymentFailed />
                    </React.Suspense>
                    }
                />
            </Route>*/}
            <Route path="/successful">
                <Route
                    path=":id"
                    element={
                    <>
                        <Helmet><title>Paiement réussi - Les-experts.ma</title></Helmet>
                        <React.Suspense
                            fallback={<div role="status" className="flex items-center justify-center h-[50vh] w-full">
                                <svg aria-hidden="true" className="w-8 h-8 text-third animate-spin  fill-gray-900" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                                <span className="sr-only text-white">Loading...</span>
                            </div>}>
                            <PaymentSuccessful />
                        </React.Suspense>
                    </>
                    }
                />
            </Route>

        </Routes>
        <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Bounce} // Ensure that the attribute value is within curly braces
        />
    </BrowserRouter>
  );
}
