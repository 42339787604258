import React from 'react';
import photoSorry from "../../images/icon_Rejected.webp";
import Confirmation from '../Confirmation';

export default function RefuseDemandePage() {
    return (
        <Confirmation
            title="Désolé"
            primaryMessage="Vous avez refusé la demande de consultation."
            secondaryMessage="Veuillez contacter le support si vous avez des questions."
            imageSrc={photoSorry}
            altText="icon Error"
        />
    );
}