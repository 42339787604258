import React, { useRef } from 'react';
import { JaaSMeeting } from '@jitsi/react-sdk';

const JitsiMeetComponent = () => {
    const generatedMeetingLink = 'https://8x8.vc/vpaas-magic-cookie-8796d75b3e99425fae680de9e2afaa26/12345?jwt=eyJraWQiOiJ2cGFhcy1tYWdpYy1jb29raWUtODc5NmQ3NWIzZTk5NDI1ZmFlNjgwZGU5ZTJhZmFhMjYvNzE5Njg5IiwidHlwIjoiSldUIiwiYWxnIjoiUlMyNTYifQ.eyJhdWQiOiJqaXRzaSIsInN1YiI6InZwYWFzLW1hZ2ljLWNvb2tpZS04Nzk2ZDc1YjNlOTk0MjVmYWU2ODBkZTllMmFmYWEyNiIsIm5iZiI6MTcyMDcwNjM5NSwiaXNzIjoiY2hhdCIsImNvbnRleHQiOnsiZmVhdHVyZXMiOnsibGl2ZXN0cmVhbWluZyI6dHJ1ZSwib3V0Ym91bmQtY2FsbCI6dHJ1ZSwic2lwLW91dGJvdW5kLWNhbGwiOnRydWUsInRyYW5zY3JpcHRpb24iOnRydWUsInJlY29yZGluZyI6ZmFsc2V9LCJ1c2VyIjp7ImhpZGRlbi1mcm9tLXJlY29yZGVyIjpmYWxzZSwibW9kZXJhdG9yIjpmYWxzZX19LCJleHAiOjE3MjA3MjA4MDAsImlhdCI6MTcyMDcwNjQwMCwicm9vbSI6IioifQ.HQiUuBSfyqAnXD44FT_1eSRTaLVLH7km5TcOGizOXCc_INXJGKIBy1-_0yG4ihXfZsChGWmDVbNY-ZfENamBHhol8y2JSOCbgx7vKfWZ834dEsWLV8RNS1kzLxw9rZL5U3EEKeW7SXtekaVdgti07SjFAbpHK0R-9wSaN8bI5JSorhaPTPd3NUYWiwhTn9nBzcGz9E_TxqdQRZQcRh43L8OPRWCtSHZnZOc3rRGWkTeFJlWE1DnzV7n7BXj0BzvN-8LYF43qLSmyMH-uaW9KxDOjir75ItiuE9ozOGsP2hNN02Yz7bHOQEWusZCE3ixdMp5R4tUB9SV-Wu5bK2Y-fQ#config.prejoinPageEnabled=false&config.startWithAudioMuted=false&config.startWithVideoMuted=false';

    return (
        <div style={{ height: '100vh', width: '100%' }}>
            <JaaSMeeting
                roomName={generatedMeetingLink}
                configOverwrite={{
                    disableThirdPartyRequests: true,
                    disableLocalVideoFlip: true,
                    backgroundAlpha: 0.5
                }}
                interfaceConfigOverwrite={{
                    VIDEO_LAYOUT_FIT: 'nocrop',
                    MOBILE_APP_PROMO: false,
                    TILE_VIEW_MAX_COLUMNS: 4
                }}
            />
        </div>
    );
};

export default JitsiMeetComponent;