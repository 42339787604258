import { useEffect, useState } from "react";
import MeetComponent from "./MeetComponent";
import Timer from "./Timer";
const JitsiMeet = ({ dateDebut, dateFin, roomName, jwt }) => {
  const [isMeetAvailable, setIsMeetAvailable] = useState(false);
  function getDateInTimezone(timezone) {
    const now = new Date();
    // Create an Intl.DateTimeFormat object to get the offset
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
    const parts = formatter.formatToParts(now);
    const year = parts.find(p => p.type === 'year').value;
    const month = parts.find(p => p.type === 'month').value;
    const day = parts.find(p => p.type === 'day').value;
    const hour = parts.find(p => p.type === 'hour').value;
    const minute = parts.find(p => p.type === 'minute').value;
    const second = parts.find(p => p.type === 'second').value;
    // Construct the date string
    const dateString = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
    // Return as a Date object
    return new Date(dateString);
  }
  const checkDateCondition = () => {
    const start = new Date(dateDebut);
    const now = getDateInTimezone(window._env_.REACT_APP_TIMEZONE)
    const targetDate = new Date(start.getTime() - 5 * 60 * 1000);
    if (now > targetDate) {
      setIsMeetAvailable(true);
    } else {
      setIsMeetAvailable(false);
    }
  };
  useEffect(() => {
    // Run checkDateCondition immediately on mount
    checkDateCondition();
    // Set up an interval to run checkDateCondition every second
    const interval = setInterval(checkDateCondition, 1000);
    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, [dateDebut]); // Dependency array to re-run if dateDebut changes
  return (
      <>
        { /* isMeetAvilable*/
          isMeetAvailable ? <MeetComponent token={jwt} roomName={roomName} dateDebut={dateDebut} dateFin={dateFin} /> :
              <div class="flex flex-col items-center justify-center space-y-8 w-full h-full bg-gradient-to-r from-green-700/10  to-white/30 animate-gradient-x text-white rounded-lg shadow-lg p-8 mx-auto font-[sans-serif]">
                <h2 class="text-2xl font-bold text-center text-white">
                  Préparez-vous pour la réunion !
                </h2>
                <p class="text-lg font-italic text-white text-center" behavior="scroll" direction="left" scrollamount="3">
                  La réunion sera disponible 5 minutes avant l'heure de début.
                </p>
                <Timer dateDebut={dateDebut} />
              </div>
          /*<MeetComponent token={jwt} roomName={roomName} dateDebut={dateDebut} dateFin={dateFin} />*/
        }
      </>
  )
}
export default JitsiMeet;