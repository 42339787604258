import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import axios from 'axios'
import HeaderSimple from "./Pages/HeaderSimple";
import {jwtDecode} from "jwt-decode";
import searchParams from "lodash";
import {toast} from "react-toastify";


const schemaLogin = Yup.object().shape({
    email: Yup.string()
        .required("Email est obligatoire")
        .email("Invalid email format"),
    password: Yup.string()
        .required("Mot de passe est obligatoire")
        .min(8, "Password must be at least 8 characters"),
});

const schema = Yup.object().shape({
    currentPassword: Yup.string()
        //.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-]{8,}$/
           // , "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial")
        .required("Mot de passe est obligatoire"),
    newPassword: Yup.string()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-]{8,}$/
            , "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial")
        .required("Mot de passe est obligatoire"),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Les mots de passe doivent correspondre')
});

const checkAuthentication  = () => {
    const userFirstName = window.localStorage.getItem('userFirstName');
    const sessionExpireAt = window.localStorage.getItem('session_expire_at');
    const isSessionValid = sessionExpireAt && new Date(sessionExpireAt) > new Date();

    // Vérifier si un utilisateur est connecté et si la session est toujours valide
    return userFirstName && isSessionValid;
};


const ChangePassword = () => {
    const [responseStatus, setResponseStatus] = useState(null)
    const [visiblePassword, setVisiblePassword] = useState(false)
    const [showPopup, setShowPopup] = useState(true);
    const location = useLocation();
    const signIn = useSignIn();
    const isAuthenticated = checkAuthentication();

    const navigate = useNavigate()


    useEffect(() => {
        // Si l'utilisateur est déjà connecté, on le redirige vers la page d'accueil ou la page de profil
        if (isAuthenticated) {
            setShowPopup(false)  // Ou '/profil', selon la redirection que vous souhaitez
        }
    }, [isAuthenticated, navigate]);

    // Function to get the current date and time plus 30 minutes
    const getExpirationDate = (minutesToAdd) => {
        const now = new Date(); // Current date and time
        now.setMinutes(now.getMinutes() + minutesToAdd); // Add minutes
        return now;
    };



    return (
        <>
            <HeaderSimple
                mainTitle="Mettre à Jour Votre Mot de Passe"
                subTitle={'Sécurisez votre compte en modifiant votre mot de passe ! '}
            ></HeaderSimple>
            <div className="bg-gray-50 dark:bg-primary font-poppins">
                <div className=" flex flex-col items-center justify-center py-6 px-4">
                    <div className="max-w-md w-full">
                        <div className="p-8 rounded-2xl bg-white shadow">
                            <Formik
                                initialValues={{
                                    currentPassword: '',
                                    newPassword: '',
                                    passwordConfirmation: ''
                                }}
                                validationSchema={schema}
                                onSubmit={async (values, { setSubmitting, setStatus }) => {
                                    try {
                                        // Récupérer le token depuis le localStorage
                                        const token = window.localStorage.getItem('accessToken');

                                        if (!token) {
                                            setStatus({error: 'Token manquant. Veuillez vous reconnecter.'});
                                            setSubmitting(false);
                                            return;
                                        }
                                        /*const {email} = decodeJwtToken(token)
                                        console.log(email)*/


                                        // Appel à l'API pour réinitialiser le mot de passe
                                        const response = await axios.post(
                                            window._env_.REACT_APP_BACKEND_URL + `/api/v1/auth/changePassword`,
                                            //`http://localhost:8083/api/v1/users`,
                                            {
                                                currentPassword: values.currentPassword,
                                                newPassword: values.newPassword,
                                                confirmationPassword: values.passwordConfirmation,
                                                /*email:email*/
                                            },
                                            {
                                                headers: { Authorization: `Bearer ${token}` } // Passer l'authToken dans les headers
                                                //params: { token: token }  // Passer le token en tant que paramètre
                                            }// Passer le token en tant que paramètre
                                        );

                                        if (response.status === 200) {
                                            setStatus({success: 'Mot de passe réinitialisé avec succès'});
                                            toast.success('Mot de passe changé avec succès'); // Affichage du message de succès avec toast
                                            setSubmitting(false);
                                            navigate('/CongChangePassword'); // Rediriger après succès
                                        }
                                    } catch (error) {
                                        if (error.response) {
                                            // Gestion des erreurs renvoyées par le backend
                                            if (error.response.status === 400) {
                                                toast.error('Mot de passe actuel incorrect'); // Toast pour "Wrong Password"
                                            } else if (error.response.status === 406) {
                                                toast.error('Les nouveaux mots de passe ne correspondent pas'); // Toast pour "Passwords are not the same"
                                            } else {
                                                toast.error('Les mot de passe incorrect. Veuillez réessayer.');
                                            }
                                        } else {
                                            toast.error('Une erreur est survenue. Veuillez réessayer.');
                                        }
                                        setSubmitting(false);
                                    }
                                    /*catch (error) {
                                        if (error.response) {
                                            // Gestion des erreurs renvoyées par le backend
                                            toast.error(error.response.data);
                                            setStatus({error: error.response.data});
                                        } else {
                                            setStatus({error: 'Une erreur est survenue. Veuillez réessayer.'});
                                        }
                                        setSubmitting(false);
                                    }*/

                                }}
                            >
                                {({errors, touched}) => (

                                    <Form className="h-auto space-y-4">
                                        {responseStatus &&
                                            <div className="text-red-500 pt-2 text-sm">{responseStatus}</div>
                                        }

                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Mot de passe actuel</label>
                                            <div className="relative flex items-center">
                                                <Field name="currentPassword" type={visiblePassword ? 'text' : 'password'}
                                                       required
                                                       className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                       placeholder="Entrez votre mot de passe actuel"/>
                                                <svg onClick={() => setVisiblePassword(!visiblePassword)}
                                                     xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                                                     className="w-4 h-4 absolute right-4 cursor-pointer"
                                                     viewBox="0 0 128 128">
                                                    <path
                                                        d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                                                        data-original="#000000"></path>
                                                </svg>
                                            </div>
                                            <div className={"h-4"}>
                                                {errors.currentPassword && touched.currentPassword ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.currentPassword}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Nouveau mot de passe</label>
                                            <div className="relative flex items-center">
                                                <Field name="newPassword" type={visiblePassword ? 'text' : 'password'}
                                                       required
                                                       className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                       placeholder="Entrez votre nouveau mot de passe"/>
                                                <svg onClick={() => setVisiblePassword(!visiblePassword)}
                                                     xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                                                     className="w-4 h-4 absolute right-4 cursor-pointer"
                                                     viewBox="0 0 128 128">
                                                    <path
                                                        d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                                                        data-original="#000000"></path>
                                                </svg>
                                            </div>
                                            <div className={"h-4"}>
                                                {errors.newPassword && touched.newPassword ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.newPassword}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Confirmer le nouveau mot de passe</label>
                                            <div className="relative flex items-center">
                                                <Field name="passwordConfirmation" type="password" required
                                                       className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                       placeholder="Confirmez votre nouveau mot de passe"/>
                                            </div>
                                            <div className={"h-4"}>
                                                {errors.passwordConfirmation && touched.passwordConfirmation ? (
                                                    <div
                                                        className="text-red-500 pt-2 text-xs">{errors.passwordConfirmation}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="!mt-8">
                                            <button type="submit"
                                                    className="w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-green-700 hover:bg-green-800 focus:outline-none">
                                                Réinitialiser le mot de passe
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                    </div>
                </div>
            </div>
            {showPopup && (
                <div
                    className="fixed  inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50 backdrop-blur-sm">
                    <div
                        className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
                        <div className="max-w-md w-full">
                            <div
                                className="font-poppins w-[400px] relative p-8 rounded-2xl bg-gray-100 border border-teal-950 shadow-xl">

                                <h2 className="text-gray-800 text-center text-xl font-bold">Accédez à votre compte</h2>
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: '',
                                    }}
                                    validationSchema={schemaLogin}
                                    onSubmit={values => {
                                        axios.post(window._env_.REACT_APP_BACKEND_URL + '/api/v1/authenticate', {  // Notez que nous appelons maintenant le backend proxy
                                            "email": values.email,
                                            "password": values.password
                                        })
                                            .then((res) => {
                                                if (res.status === 200) {
                                                    // Décoder le access_token
                                                    const decodedToken = jwtDecode(res.data.access_token);

                                                    // Extraire les informations du token
                                                    const {
                                                        firstName,
                                                        lastName,
                                                        tel,
                                                        role,
                                                        email
                                                    } = decodedToken;
                                                    // Maintenant vous avez accès au rôle de l'utilisateur depuis le token JWT

                                                    /*console.log('firstName:', firstName);
                                                    console.log('lastName:', lastName);
                                                    console.log('role:', role);
                                                    console.log('email:', email);*/

                                                    console.log('User Info:', firstName, lastName,tel, role, email);

                                                    // Récupérer le rôle directement depuis la réponse JSON
                                                    /*const role = res.data.role;
                                                    console.log('role:', role);*/
                                                    if (signIn({
                                                        auth: {
                                                            token: res.data.access_token,
                                                            type: 'Bearer'
                                                        },
                                                        //refresh: res.data.refresh_token,
                                                        userState: {
                                                            email: values.email,
                                                            fullName: firstName + " " + lastName,
                                                            role: role
                                                        }
                                                    })) {
                                                        window.localStorage.setItem('session_expire_at', getExpirationDate(60))
                                                        window.localStorage.setItem('accessToken', res.data.access_token);
                                                        window.localStorage.setItem('userFirstName', firstName);  // Storing first name
                                                        window.localStorage.setItem('userLastName', lastName);    // Storing last name
                                                        window.localStorage.setItem('userTel', tel);    // Storing last name
                                                        window.localStorage.setItem('userEmail', email);
                                                        window.localStorage.setItem('role', role);    // Storing last name

                                                        // Storing reservationData in localStorage
                                                        //window.localStorage.setItem('reservationData', JSON.stringify(location.state.reservationData));

                                                        const lastRoute = window.localStorage.getItem('lastRoute')
                                                        // Redirection selon le rôle de l'utilisateur
                                                        if (lastRoute) {
                                                            navigate(lastRoute);
                                                            setShowPopup(false);
                                                            // Rafraîchir la page après la navigation
                                                            window.location.reload();
                                                        } else {
                                                            if (role === 'EXPERT') {
                                                                navigate('/');
                                                            } else if (role === 'CLIENT') {
                                                                navigate('/');
                                                            } else {
                                                                navigate('/');
                                                            }
                                                        }
                                                    } else {
                                                        setResponseStatus('Erreur lors de la connexion.');
                                                    }
                                                }

                                            }).catch(function (error) {
                                            if (error.response?.status === 401) {
                                                setResponseStatus('Mot de passe incorrect')
                                            } else if (error.response?.status === 404) {
                                                setResponseStatus('Utilisateur non trouvé')
                                            } else if (error.response) {
                                                // The request was made and the server responded with a status code
                                                // that falls out of the range of 2xx
                                                setResponseStatus('Erreur de serveur interne veuillez réessayer plus tard')
                                            } else if (error.request) {
                                                // The request was made but no response was received
                                                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                                // http.ClientRequest in node.js
                                                setResponseStatus('Erreur de serveur interne veuillez réessayer plus tard')
                                            } else {
                                                // Something happened in setting up the request that triggered an Error
                                                console.log('Error', error.message);
                                            }
                                        });
                                    }}
                                >
                                    {({errors, touched}) => (

                                        <Form className="mt-8 space-y-4">
                                            {responseStatus &&
                                                <div
                                                    className="text-red-500 pt-2 text-sm">{responseStatus}</div>
                                            }
                                            <div>
                                                <label
                                                    className="text-gray-800 text-sm mb-2 block">Email
                                                                                                 d'utilisateur</label>
                                                <div className="relative flex items-center">
                                                    <Field name="email" type="text" required
                                                           className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-blue-600"
                                                           placeholder="Saisissez votre email"/>

                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                         fill="#bbb" stroke="#bbb"
                                                         className="w-4 h-4 absolute right-4"
                                                         viewBox="0 0 24 24">
                                                        <circle cx="10" cy="7" r="6"
                                                                data-original="#000000"></circle>
                                                        <path
                                                            d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                                            data-original="#000000"></path>
                                                    </svg>
                                                </div>
                                                <div className={"h-6"}>
                                                    {errors.email && touched.email ? (
                                                        <div
                                                            className="text-red-500 pt-2 text-xs">{errors.email}</div>
                                                    ) : null}
                                                </div>

                                            </div>

                                            <div>
                                                <label className="text-gray-800 text-sm mb-2 block">Mot
                                                                                                    de
                                                                                                    passe</label>
                                                <div className="relative flex items-center">
                                                    <Field name="password"
                                                           type={visiblePassword ? 'text' : 'password'}
                                                           required
                                                           className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                           placeholder="Entrer le mot de passe"/>
                                                    <svg
                                                        onClick={() => setVisiblePassword(!visiblePassword)}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="#bbb" stroke="#bbb"
                                                        className="w-4 h-4 absolute right-4 cursor-pointer"
                                                        viewBox="0 0 128 128">
                                                        <path
                                                            d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                                                            data-original="#000000"></path>
                                                    </svg>
                                                </div>
                                                <div className={"h-6"}>
                                                    {errors.password && touched.password ? (
                                                        <div
                                                            className="text-red-500 pt-2 text-xs">{errors.password}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div
                                                className="flex flex-wrap items-center justify-between gap-4">
                                                <div className="text-sm">
                                                    <a href="jajvascript:void(0);"
                                                       className="text-green-700 hover:underline font-semibold">
                                                        Mot de passe oublié?
                                                    </a>
                                                </div>
                                            </div>

                                            <div className="!mt-8">
                                                <button type="submit"
                                                        className="w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-green-700 hover:bg-green-800 focus:outline-none">
                                                    Se connecter
                                                </button>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default ChangePassword;