import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import axios from 'axios'
import decodeJwtToken from "./decodeToken";
import logoHbm from "../images/Logo_Les-Experts_FNL-10.webp";



const schema = Yup.object().shape({
    firstName: Yup.string().required("Nom est obligatoire"),
    lastName: Yup.string().required("Prénom est obligatoire"),
    tel: Yup.string()
        .required("Le numéro de téléphone est obligatoire")
        .matches(/^(?:0)[5-7]\d{8}$/, "Numéro de téléphone invalide"),
    email: Yup.string()
        .required("Email est obligatoire")
        .email("Invalid email format"),
    password: Yup.string()
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;"'|\\,.<>\/?`~\-]{8,}$/
            , "Doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial")
        .required("Mot de passe est obligatoire"),
    passwordConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre')
});


const Register = () => {
    const [responseStatus, setResponseStatus] = useState(null)
    const [visiblePassword, setVisiblePassword] = useState(false)

    const signIn = useSignIn()
    const isAuthenticated = useIsAuthenticated()

    const navigate = useNavigate()


    if (isAuthenticated) {
        return <Navigate to={"/"} />
    }

    // Function to get the current date and time plus 30 minutes
    const getExpirationDate = (minutesToAdd) => {
        const now = new Date(); // Current date and time
        now.setMinutes(now.getMinutes() + minutesToAdd); // Add minutes
        return now;
    };


    return (
        <>
            <div className="bg-gray-50 dark:bg-primary font-poppins">
                <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
                    <div className="max-w-md w-full">
                        <a href="/"><img
                            src={logoHbm} alt="logo" className='w-40 mb-8 mx-auto block'/>
                        </a>
                        <div className="p-8 rounded-2xl bg-white shadow">
                            <h2 className="text-gray-800 text-center text-2xl font-bold">Créer votre compte</h2>
                            <Formik
                                initialValues={{
                                    firstName: '',
                                    lastName: '',
                                    tel : '',
                                    email: '',
                                    password: '',
                                    passwordConfirmation: ''
                                }}
                                validationSchema={schema}
                                onSubmit={values => {
                                    axios.post(window._env_.REACT_APP_BACKEND_URL+'/api/v1/auth/register', {
                                        "firstname": values.firstName,
                                        "lastname": values.lastName,
                                        "tel": values.tel,
                                        "email": values.email,
                                        "password": values.password,
                                        "role": "CLIENT"
                                    })
                                        .then((res) => {
                                            if (res.status === 200) {
                                                const {firstName, lastName,tel,role,email} = decodeJwtToken(res.data.access_token)
                                                setResponseStatus(null)
                                                console.log('User Info:', firstName, lastName,tel, role, email);
                                                if (signIn({
                                                    auth: {
                                                        token: res.data.access_token,
                                                        type: 'Bearer'
                                                    },
                                                    //refresh: res.data.refresh_token,
                                                    userState: {
                                                        email: values.email,
                                                        fullName: firstName + " " + lastName,
                                                        tel : tel,
                                                        role: role
                                                    }
                                                })) {
                                                    window.localStorage.setItem('session_expire_at', getExpirationDate(60))
                                                    window.localStorage.setItem('userFirstName', firstName);  // Storing first name
                                                    window.localStorage.setItem('accessToken', res.data.access_token);
                                                    window.localStorage.setItem('userLastName', lastName);    // Storing last name
                                                    window.localStorage.setItem('userTel', tel);   // Storing last name
                                                    window.localStorage.setItem('userEmail', email);
                                                    window.localStorage.setItem('role', role);    // Storing last name
                                                    const lastRoute = window.localStorage.getItem('lastRoute')
                                                    // Redirection selon le rôle de l'utilisateur
                                                    if (lastRoute) {
                                                        navigate(lastRoute);
                                                    } else {
                                                        if (role === 'EXPERT') {
                                                            navigate('/');
                                                        } else if (role === 'CLIENT') {
                                                            navigate('/');
                                                        } else {
                                                            navigate('/');
                                                        }
                                                    }
                                                } else {
                                                    setResponseStatus('Erreur lors de la connexion.');
                                                }
                                            }
                                        }).catch(function (error) {
                                        if (error?.response?.status === 409) {
                                            // The request was made and the server responded with a status code
                                            // that falls out of the range of 2xx
                                            setResponseStatus('Cet adresse mail est déjà utilisée')
                                        } else if (error.response) {
                                            // The request was made and the server responded with a status code
                                            // that falls out of the range of 2xx
                                            setResponseStatus('Erreur de serveur interne veuillez réessayer plus tard')
                                        } else if (error.request) {
                                            // The request was made but no response was received
                                            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                                            // http.ClientRequest in node.js
                                            setResponseStatus('Erreur de serveur interne veuillez réessayer plus tard')
                                        } else {
                                            // Something happened in setting up the request that triggered an Error
                                            console.log('Error', error.message);
                                        }
                                    })
                                }}
                            >
                                {({errors, touched}) => (

                                    <Form className="mt-8 h-auto space-y-4">
                                        {responseStatus &&
                                            <div className="text-red-500 pt-2 text-sm">{responseStatus}</div>
                                        }
                                        <div className={"flex flex-row space-x-5 items-center justify-center"}>
                                            <div>
                                                <label className="text-gray-800 text-sm mb-2 block">Prénom </label>
                                                <div className="relative flex items-center">
                                                    <Field name="firstName" type="text" required
                                                           className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                           placeholder="Enter votre prénom"/>
                                                </div>
                                                <div className={"h-4"}>
                                                    {errors.firstName && touched.firstName ? (
                                                        <div
                                                            className="text-red-500 pt-2 text-xs">{errors.firstName}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div>
                                                <label className="text-gray-800 text-sm mb-2 block">Nom</label>
                                                <div className="relative flex items-center">
                                                    <Field name="lastName" type="text" required
                                                           className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                           placeholder="Enter votre nom"/>
                                                </div>
                                                <div className={"h-4"}>
                                                    {errors.lastName && touched.lastName ? (
                                                        <div
                                                            className="text-red-500 pt-2 text-xs">{errors.lastName}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Téléphone</label>
                                            <div className="relative flex items-center">
                                                <Field name="tel" type="text" required
                                                       className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                       placeholder="Saisir votre telephone"/>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                                                     className="w-4 h-4 absolute right-4" viewBox="0 0 24 24">
                                                    <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                                                    <path
                                                        d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                                        data-original="#000000"></path>
                                                </svg>
                                            </div>
                                            <div className={"h-4"}>
                                                {errors.tel && touched.tel ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.tel}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Email</label>
                                            <div className="relative flex items-center">
                                                <Field name="email" type="text" required
                                                       className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                       placeholder="Enter l'email"/>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                                                     className="w-4 h-4 absolute right-4" viewBox="0 0 24 24">
                                                    <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
                                                    <path
                                                        d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                                                        data-original="#000000"></path>
                                                </svg>
                                            </div>
                                            <div className={"h-4"}>
                                                {errors.email && touched.email ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.email}</div>
                                                ) : null}
                                            </div>
                                        </div>


                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Mot de passe</label>
                                            <div className="relative flex items-center">
                                                <Field name="password" type={visiblePassword ? 'text' : 'password'}
                                                       required
                                                       className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                       placeholder="Enter password"/>
                                                <svg onClick={() => setVisiblePassword(!visiblePassword)}
                                                     xmlns="http://www.w3.org/2000/svg" fill="#bbb" stroke="#bbb"
                                                     className="w-4 h-4 absolute right-4 cursor-pointer"
                                                     viewBox="0 0 128 128">
                                                    <path
                                                        d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                                                        data-original="#000000"></path>
                                                </svg>
                                            </div>
                                            <div className={"h-4"}>
                                                {errors.password && touched.password ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.password}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Mot de passe</label>
                                            <div className="relative flex items-center">
                                                <Field name="passwordConfirmation" type="password" required
                                                       className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                       placeholder="Enter password"/>
                                            </div>
                                            <div className={"h-4"}>
                                                {errors.passwordConfirmation && touched.passwordConfirmation ? (
                                                    <div
                                                        className="text-red-500 pt-2 text-xs">{errors.passwordConfirmation}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="!mt-8">
                                            <button type="submit"
                                                    className="w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-green-700 hover:bg-green-800 focus:outline-none">
                                                S'inscrire
                                            </button>
                                        </div>
                                        <p className="text-gray-800 text-sm !mt-8 text-center">Vous avez déja un compte
                                                                                               ?
                                            <Link to="/login"
                                                  className="text-third hover:underline ml-1 whitespace-nowrap font-semibold">Connecter-vous
                                                                                                                              maintenant</Link>
                                        </p>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <Link to='/'
                                  className="flex items-center space-x-2 bg-green-600 hover:bg-green-800 text-gray-100 px-4 py-2 mt-12 rounded transition duration-150"
                                  title="Return Home">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                     fill="currentColor">
                                    <path fill-rule="evenodd"
                                          d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                                          clip-rule="evenodd"></path>
                                </svg>
                                <span>Return Home</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Register;