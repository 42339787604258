import React, { useEffect, useState } from "react";
import { JitsiMeeting } from "@jitsi/react-sdk";
import { jwtDecode } from "jwt-decode";

function MeetComponent({ dateDebut, dateFin, token, roomName }) {
  const [isValid, setIsValid] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [isConferenceRunning, setConferenceRunning] = useState(false);
  const [currentTime, setCurrentTime] = useState()
  const [speakerStats, setSpeakerStats] = useState({
    speakerStats: {},
  });
  function getDateInTimezone(timezone) {
    const now = new Date();
    // Create an Intl.DateTimeFormat object to get the offset
    const formatter = new Intl.DateTimeFormat('en-US', {
      timeZone: timezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    });
    const parts = formatter.formatToParts(now);
    const year = parts.find(p => p.type === 'year').value;
    const month = parts.find(p => p.type === 'month').value;
    const day = parts.find(p => p.type === 'day').value;
    const hour = parts.find(p => p.type === 'hour').value;
    const minute = parts.find(p => p.type === 'minute').value;
    const second = parts.find(p => p.type === 'second').value;
    // Construct the date string
    const dateString = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
    // Return as a Date object
    return new Date(dateString);
  }
  const now = getDateInTimezone(window._env_.REACT_APP_TIMEZONE)
  console.log("-----------------------------" + now + typeof now + "------------------------------------")
  /*useEffect(() => {
    let intervalId;
    if (isConferenceRunning) {
      // Function to update the state
      const updateValue = () => {
        console.log('updated')
        setSpeakerStats(prevValue => prevValue + 1);
      };
      // Set up the interval
      intervalId = setInterval(updateValue, 10000);
    }
    // Cleanup on component unmount or when isRunning changes
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isConferenceRunning]); // Effect depends on isRunning */
  // Get the query string from the URL
  //const queryString = window.location.search;
  // Create a URLSearchParams object
  //const urlParams = new URLSearchParams(queryString);
  // Extract query parameters
  //const param1 = urlParams.get("query");
  //console.log(param1);
  const calculateRemainingTime = (dateFin) => {
    const end = new Date(dateFin);
    // Calculate time remaining until the end date
    const timeRemaining = end - now;
    return timeRemaining;
  };
  const [showPopup, setShowPopup] = useState(false);
  const checkDateRange = (dateDebut, dateFin) => {
    const start = new Date(dateDebut);
    const end = new Date(dateFin);
    console.log(now, start, end);
    if (now <= end) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  useEffect(() => {
    checkDateRange(dateDebut, dateFin);
    const timeRemaining = calculateRemainingTime(dateFin);
    setRemainingTime(timeRemaining);
  }, [dateDebut, dateFin]);
  return (
      <>
        <div className="flex w-full h-full bg-white rounded-2xl overflow-hidden">
          <JitsiMeeting
              domain={window._env_.REACT_APP_LIEN_MEET}
              roomName={roomName}
              configOverwrite={{
                startWithAudioMuted: true,
                disableModeratorIndicator: true,
                startScreenSharing: true,
                enableEmailInStats: false,
              }}
              interfaceConfigOverwrite={{
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
              }}
              jwt={token}
              onApiReady={(externalApi) => {
                // here you can attach custom event listeners to the Jitsi Meet External API
                // you can also store it locally to execute commands
                //document.getElementById('endmeet').addEventListener('click',()=>{
                //  externalApi.executeCommand("hangup")
                //})
                /*externalApi.addListener("videoConferenceJoined", () => {
                  setInterval(async () => {
                    console.log("speaker stats");
                    const stats = await externalApi.getSpeakerStats();
                    console.log(stats.speakerStats);
                    speakerStats.speakerStats = stats.speakerStats
                  }, 10000);
                });
                externalApi.addListener("videoConferenceLeft", async (e) => {
                  console.log("---------------------------------");
                  console.log(speakerStats);
                  console.log("---------------------------------");
                });*/
                /*externalApi.addListener("videoConferenceJoined", (api) => {
                  if (decoded.moderator) {
                    externalApi.startRecording({
                      mode: "file", //recording mode, either `local`, `file` or `stream`.
                      //dropboxToken: string, //dropbox oauth2 token.
                      onlySelf: false,  //Whether to only record the local streams. Only applies to `local` recording mode.
                      shouldShare: false, //whether the recording should be shared with the participants or not. Only applies to certain jitsi meet deploys.
                    })
                  }
                }) */
                /* meetingEndTime = new Date(dateFin).getTime(); // Convert to timestamp
                const fiveMinutesInMs = 2 * 60 * 1000; // 5 minutes in milliseconds
                const currentTime = now.getTime()// Current timestamp
                // Calculate when to show the notification (5 minutes before meeting end)
                const timeUntilWarning = meetingEndTime - fiveMinutesInMs - currentTime;
                // Only set the timeout if the warning is in the future
                if (timeUntilWarning > 0) {
                  const warningTimeout = setTimeout(() => {
                    window.alert("the meet will end")
                    return () => clearTimeout(timer);
                    //externalApi.executeCommand("hangup");
                  }, timeUntilWarning);
                  // Cleanup timeout when component unmounts
                  return () => clearTimeout(warningTimeout);
                } else {
                  externalApi.executeCommand("hangup");
                }
                if (!isValid) {
                  externalApi.executeCommand("hangup");
                }
                if (remainingTime > 0) {
                  // Set a timeout to trigger the event when the end date arrives
                  const timer = setTimeout(() => {
                    externalApi.executeCommand("hangup");
                  }, remainingTime);
                  // Cleanup timeout if component unmounts
                  return () => clearTimeout(timer);
                } else {
                  // If the end date is in the past, trigger the event immediately
                  externalApi.executeCommand("hangup");
                }*/
                const meetingEndTime = new Date(dateFin).getTime(); // Meeting end timestamp
                const fiveMinutesInMs = 2 * 60 * 1000; // 5 minutes in milliseconds
                const currentTime = now.getTime(); // Current timestamp
                const remainingTime = meetingEndTime - currentTime;
                // Function to handle meeting ending
                const endMeeting = () => externalApi.executeCommand("hangup");
                // Show warning 5 minutes before meeting end
                if (remainingTime > fiveMinutesInMs) {
                  const timeUntilWarning = remainingTime - fiveMinutesInMs;
                  const warningTimeout = setTimeout(() => {
                    window.alert("The meeting will end soon");
                  }, timeUntilWarning);
                  const endTimeout = setTimeout(endMeeting, remainingTime);
                  // Cleanup both timeouts when component unmounts
                  return () => {
                    clearTimeout(warningTimeout);
                    clearTimeout(endTimeout);
                  };
                } else if (remainingTime > 0) {
                  // Meeting ends soon, no need for a warning
                  const endTimeout = setTimeout(endMeeting, remainingTime);
                  // Cleanup timeout when component unmounts
                  return () => clearTimeout(endTimeout);
                } else {
                  // Meeting already ended
                  endMeeting();
                }
              }}
              onReadyToClose={() => { }}
              getIFrameRef={(iframeRef) => {
                iframeRef.style.height = "100%";
                iframeRef.style.width = "100%";
              }}
          />
        </div>
      </>
  );
}
export default MeetComponent;