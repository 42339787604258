import React from 'react';
import photoCongrat from "../../images/icon_Congratulation.webp";
import Confirmation from '../Confirmation';

export default function CongratulationChangePassword() {
    return (
        <Confirmation
            title="Félicitations !!"
            primaryMessage="Votre mot de passe a été changé avec succès."
            secondaryMessage="Vous pouvez maintenant vous connecter avec votre nouveau mot de passe."
            tertiaryMessage="Si vous rencontrez des problèmes, n'hésitez pas à contacter le support."
            imageSrc={photoCongrat}
            altText="icon Validate"
        />
    );
}