import React from 'react';
import { Navigate } from 'react-router-dom';

const getUserRole = () => {
    return localStorage.getItem('role'); // Récupérer directement le rôle depuis le localStorage
};
const RequireRole = ({ allowedRoles, children }) => {
    const role = getUserRole(); // Récupérer le rôle de l'utilisateur

    if (!role || !allowedRoles.includes(role)) {
        return <Navigate to="/unauthorized" replace />; // Redirige vers une page non autorisée si le rôle ne correspond pas
    }

    return children; // Rendre le composant enfant si le rôle est autorisé
};
export default RequireRole;