import apiClient from './apiService';

export const getPlanning = async (semaine, idExpert) => {
    try {
        const formattedDate = new Date(semaine).toISOString();

        // Extraire la partie de la date avant le caractère 'T' pour obtenir le format 'yyyy-MM-dd'
        const dateOnly = formattedDate.split('T')[0];

        const response = await apiClient.post('/creneaux/planningSemaine', {
            semaine: dateOnly,
            idExpert: idExpert
        });

        return response.data;
    } catch (error) {
        // Afficher l'erreur détaillée dans la console
        console.error("Erreur lors de la récupération du planning:", error);

        // Lancer l'erreur pour la gérer ailleurs si nécessaire
        throw error;
    }
};


