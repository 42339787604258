import React, {useEffect, useState} from 'react';
import PropTypes from "prop-types";
import {Link, useNavigate} from "react-router-dom";

export default function Confirmation({ title, primaryMessage, secondaryMessage, tertiaryMessage, imageSrc, altText }) {
    const [timeLeft, setTimeLeft] = useState(20); // Initialisation à 15 secondes
    const navigate = useNavigate();

    useEffect(() => {
        // Décrémenter le compteur chaque seconde
        const intervalId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        // Rediriger vers l'accueil après 15 secondes
        const timer = setTimeout(() => {
            navigate('/');
        }, 20000);

        // Nettoyer le timer et l'intervalle lors du démontage du composant
        return () => {
            clearInterval(intervalId);
            clearTimeout(timer);
        };
    }, [navigate]);

    return (
        <div className="flex justify-center items-center flex-col mx-auto my-auto w-full h-screen">
            <div className="pb-5">
                <div>
                    <img src={imageSrc} alt={altText}/>
                </div>
            </div>
            <div className="flex flex-col justify-center items-center w-3/4">
                <h1 className="font-montserrat font-semibold text-5xl text-gray-700 p-2">{title}</h1>
                <h3 className="font-montserrat font-medium text-green-700 text-2xl p-1">{primaryMessage}</h3>
                {secondaryMessage && (
                    <h3 className="font-montserrat font-normal text-2xl text-gray-700 p-1">{secondaryMessage}</h3>
                )}
                {tertiaryMessage && (
                    <h3 className="font-montserrat font-normal text-2xl text-gray-700 p-1">{tertiaryMessage}</h3>
                )}
            </div>
            <div className="flex font-poppins flex-col mt-12 space-y-3 items-center justify-center">
                <h1>Vous serez redirigé vers l'accueil dans <span className={" font-semibold text-green-700 "}>{timeLeft} secondes...</span></h1>
                <Link to='/'
                      className="flex items-center space-x-2 bg-green-600 hover:bg-green-800 text-gray-100 px-4 py-2 rounded transition duration-150"
                      title="Return Home">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                         fill="currentColor">
                        <path fill-rule="evenodd"
                              d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                              clip-rule="evenodd"></path>
                    </svg>
                    <span>Return Home</span>
                </Link>
            </div>
        </div>
    );
}

Confirmation.propTypes = {
    title: PropTypes.string.isRequired,
    primaryMessage: PropTypes.string.isRequired,
    secondaryMessage: PropTypes.string,
    tertiaryMessage: PropTypes.string,
    imageSrc: PropTypes.string.isRequired,
    altText: PropTypes.string.isRequired,
};



