import React, { useState, useEffect } from 'react';
import { useField } from "formik";
import PropTypes from 'prop-types';

const StarsRating = ({ name }) => {
    const [field, meta, helpers] = useField(name);
    const [rating, setRating] = useState(field.value || 0);
    const starsArr = [1, 2, 3, 4, 5];



    const fillStars = () => {
        starsArr.forEach(i => {
            const star = document.getElementById(`star-${i}`);
            if (star) {
                star.style.fill = i <= rating ? "#FDE047" : "#F8FAFC";
            }
        });
    };

    useEffect(() => {
        fillStars();
    }, [rating]);

    const showHoverEffect = (id) => {
        if (id <= rating) return;
        for (let i = rating + 1; i <= id; i++) {
            const star = document.getElementById(`star-${i}`);
            if (star) star.style.fill = "#FDE047";
        }
    };

    const removeHoverEffect = () => {
        fillStars();
    };

    const handleRating = (newRating) => {
        setRating(newRating);
        helpers.setValue(newRating);
    };

    return (
        <div className="flex py-3 space-x-3">
            {starsArr.map((id) => (
                <div key={id}>
                    <svg
                        className="w-8 h-8 cursor-pointer"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 22 20"
                    >
                        <path
                            id={`star-${id}`}
                            stroke="#94A3B8"
                            strokeWidth="1"
                            d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
                            onClick={() => handleRating(id)}
                            onMouseEnter={() => showHoverEffect(id)}
                            onMouseLeave={removeHoverEffect}
                        />
                    </svg>
                </div>
            ))}
            {/*{meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null}*/}
        </div>
    );
};

StarsRating.propTypes = {
    name: PropTypes.string.isRequired,
};

export default StarsRating;