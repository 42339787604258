import React, {useEffect, useRef, useState} from 'react';
import {faDownload, faStar} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/carousel-custom.css";
import {Tooltip, Button, Typography} from "@material-tailwind/react";
import axios from "axios"; // Import your custom CSS
import { Link } from 'react-router-dom';
import apiClient from "../../Services/apiService";



const ExpertCard = ({ expert }) => {
    const { nomExpert, prenomExpert, titreExpert, photoExpert } = expert;
    return (
        <div className="bg-white w-full max-w-xs h-96 rounded-lg shadow-[0_2px_8px_-1px_rgba(0,0,0,0.3)]  p-4 flex flex-col">
            <img
                src={`data:image/jpeg;base64,${photoExpert}`}
                alt={`${nomExpert} ${prenomExpert}`}
                className="w-40 h-40 mt-2 p-1 rounded-full shadow-lg ring-2 ring-amber-300 mx-auto mb-4"
            />
            <div className="flex flex-col justify-center items-center flex-grow">
                <h2 className="text-lg font-poppins font-semibold text-gray-800 mb-2">{`${nomExpert} ${prenomExpert}`}</h2>
                <p className="text-teal-900 text-md  font-poppins font-semibold mb-4">{titreExpert}</p>
                <p className="text-gray-600">{/*descriptionExpert*/}</p>
            </div>
            <div className="flex flex-row justify-center mt-auto space-x-5 items-center">
                <div className="expert-info py-1">
                    <p className="small-title text-xs">
                        Expert Instruction
                        <span className="rating-star">
                            <FontAwesomeIcon icon={faStar} className="text-yellow-300" />4.9
                        </span>
                    </p>
                </div>
                <div className="flex invisible md:visible justify-center items-center text-white font-semibold text-xs rounded-full">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="h-6 w-6 text-gray-500"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                        />
                    </svg>
                </div>
            </div>
        </div>
    );
};

const NosExperts = () => {
    const [currentDomain, setCurrentDomain] = useState(null);
    const [currentExperts, setCurrentExperts] = useState([]);
    const [domaines, setDomaines] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const initialLoad = useRef(true); // Utilisation de useRef pour garder une trace du chargement initial
    const [showPrevNext, setShowPrevNext] = useState(false); // State to control visibility of prev/next arrows
    const [tooltipVisible, setTooltipVisible] = useState(false); // State to control tooltip visibility
    const [linkDisabled, setLinkDisabled] = useState(false);

    useEffect(() => {
        /*const fetchDomaines = async () => {
            try {
                const response = await apiClient.get('/domaines/list');
                const domainesWithExperts = await Promise.all(response.data.map(async (domain) => {
                    const expertsResponse = await apiClient.get(`/listExpertDomaine/experts/domaine/${domain.idDomaine}`);
                    return { ...domain, expertsCount: expertsResponse.data.length };
                }));

                const filteredDomaines = domainesWithExperts.filter(domain => domain.expertsCount > 0);
                setDomaines(filteredDomaines);

                if (filteredDomaines.length > 0) {
                    setCurrentDomain(filteredDomaines[0]);
                }
            } catch (error) {
                console.error('Error fetching services:', error);
            }
        };*/
        const fetchDomaines = async () => {
            try {
                const cachedDomaines = localStorage.getItem('domaines');
                if (cachedDomaines) {
                    // Si les données sont en cache, les utiliser directement
                    const domainesData = JSON.parse(cachedDomaines);
                    setDomaines(domainesData);
                    setCurrentDomain(domainesData[0]); // Sélectionner le premier domaine
                } else {
                    // Sinon, appeler l'API et mettre en cache
                    const response = await apiClient.get('/domaines/list');
                    const domainesWithExperts = await Promise.all(response.data.map(async (domain) => {
                        const expertsResponse = await apiClient.get(`/listExpertDomaine/experts/domaine/${domain.idDomaine}`);
                        return { ...domain, expertsCount: expertsResponse.data.length };
                    }));

                    const filteredDomaines = domainesWithExperts.filter(domain => domain.expertsCount > 0);
                    localStorage.setItem('domaines', JSON.stringify(filteredDomaines)); // Stocker les domaines dans localStorage
                    setDomaines(filteredDomaines);
                    if (filteredDomaines.length > 0) {
                        setCurrentDomain(filteredDomaines[0]);
                    }
                }
            } catch (error) {
                console.error('Erreur lors du chargement des services :', error);
            }
        };

        fetchDomaines();
    }, []);

    useEffect(() => {

        const fetchExpertsForDomain = async () => {
            try {
                if (currentDomain) {
                    const cachedExperts = localStorage.getItem(`experts_${currentDomain.idDomaine}`);
                    if (cachedExperts) {
                        setCurrentExperts(JSON.parse(cachedExperts));
                    } else {
                        const response = await apiClient.get(`/listExpertDomaine/experts/domaine/${currentDomain.idDomaine}`);
                        localStorage.setItem(`experts_${currentDomain.idDomaine}`, JSON.stringify(response.data));
                        setCurrentExperts(response.data);
                    }
                }
            } catch (error) {
                console.error('Erreur lors du chargement des experts :', error);
            }
        };

        /*
        const fetchExpertsForDomain = async () => {
            try {
                if (currentDomain) {
                    const response = await apiClient.get(`/listExpertDomaine/experts/domaine/${currentDomain.idDomaine}`);
                    setCurrentExperts(response.data);
                }
            } catch (error) {
                console.error('Error fetching experts:', error);
            }
        };*/

        if (currentDomain) {
            fetchExpertsForDomain();
        }
    }, [currentDomain]);

    // Effect to fetch details of experts when currentExperts change
    useEffect(() => {
        const fetchExpertDetails = async () => {
            try {
                setIsLoading(true);

                const promises = currentExperts.map(async (expert) => {
                    /*const services = await getExpertServicesById(expert.idExpert.idExpert);
                    const certificats = await getExpertCertificatById(expert.idExpert.idExpert);
                    const competences = await getExpertCompetenceById(expert.idExpert.idExpert);
                    const formations = await getExpertFormationById(expert.idExpert.idExpert);
                    const experiences = await getExpertExperienceById(expert.idExpert.idExpert);*/
                    const details = await apiClient.get(`/experts/${expert.idExpert.idExpert}/titles`)

                    return {
                        ...expert,
                        details:details.data
/*                        services,
                        certificats,
                        competences,
                        formations,
                        experiences,*/
                    };
                });

                const expertsWithData = await Promise.all(promises);

                setCurrentExperts(expertsWithData);
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching expert details:', error);
                setIsLoading(false);
            }
        };

        // Only fetch expert details when currentExperts change and it's not the initial load
        if (initialLoad.current && currentExperts.length > 0) {
            fetchExpertDetails();
            initialLoad.current = false;
        }
    }, [currentExperts]); // Include currentExperts as a dependency

    // Handler to update currentDomain when a new domain is selected
    const handleDomainChange = async (domain) => {
        setCurrentDomain(domain);
        initialLoad.current = true; // Reset initialLoad.current when changing domain
    };




    const NextArrow = ({ onClick }) => {
        return (
            <button
                className={`${
                    currentExperts.length > 4 ? 'md:block' : 'md:hidden'
                } ${
                    currentExperts.length <= 1 ? 'hidden' : 'block'
                }
                text-green-800 hover:text-white justify-center items-center transform border border-solid border-indigo-600 bg-gray-200 p-4 transition-all duration-500 rounded-full hover:bg-indigo-600`}
                onClick={onClick}
            >
                <svg className="h-5 w-5 justify-center items-center"
                     xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M5.99984 4.00012L10 8.00029L5.99748 12.0028" stroke="currentColor"
                          strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        );
    };

    const PrevArrow = ({ onClick }) => {
        return (
            <button
                className={`${
                    currentExperts.length > 4 ? 'md:block' : 'md:hidden'
                } ${
                    currentExperts.length <= 1 ? 'hidden' : 'block'
                }
                 text-green-800 hover:text-white justify-center items-center transform border border-solid border-indigo-600 bg-gray-200 p-4 transition-all duration-500 rounded-full hover:bg-indigo-600`}
                onClick={onClick}
            >
                <svg className="h-5 w-5 justify-center items-center"
                     xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M10.0002 11.9999L6 7.99971L10.0025 3.99719" stroke="currentColor"
                          strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        );
    };


    const toggleTooltip = () => {
        setTooltipVisible(!tooltipVisible); // Inverse la visibilité de l'infobulle
    };

    const settings = {
        infinite: currentExperts.length > 4, // Set to true only if more than 1 expert
        speed: 600,
        slidesToShow: currentExperts.length >= 1 ? 4 : currentExperts.length,
        slidesToScroll: 1,
        autoplay: currentExperts.length > 1, // Enable autoplay only if more than 1 expert
        autoplaySpeed: 3000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1480,
                settings: {
                    slidesToShow: currentExperts.length >= 2 ? 2 : currentExperts.length,
                    slidesToScroll: 2,
                    infinite: currentExperts.length > 1,
                    dots: currentExperts.length > 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: currentExperts.length >= 2 ? 2 : currentExperts.length,
                    slidesToScroll: 2,
                    infinite: currentExperts.length > 1,
                    dots: currentExperts.length > 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: currentExperts.length >= 2 ? 2 : currentExperts.length,
                    slidesToScroll: 2,
                    initialSlide: 0,
                    infinite: currentExperts.length > 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    infinite: currentExperts.length > 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: currentExperts.length > 1,
                    autoplay: true,
                }
            }
        ]
    };


    return (
        <div className="flex flex-col p-2 our-services w-3/4 mx-auto items-center justify-center">
            <h2 className="title-service font-poppins text-3xl font-bold my-5 text-center text-gray-700">Nos
                                                                                                         experts </h2>
            <div className="flex w-auto justify-center flex-row flex-wrap rounded-full py-4 px-5 md:bg-gray-100 gap-2">
                {domaines.map(domain => (
                    <button
                        key={domain.idDomaine}
                        className={`bg-green-600 hover:bg-green-800 focus:bg-green-900 py-2 px-5 rounded-full text-white ${currentDomain && currentDomain.idDomaine === domain.idDomaine ? 'bg-green-800' : ''}`}
                        onClick={() => handleDomainChange(domain)}
                    >
                        {domain.nomDomaine}
                    </button>
                ))}
            </div>
            <div className="w-full md:px-12">
                {currentDomain && (
                    <div className="w-full flex items-center justify-center">
                        <PrevArrow onClick={() => document.querySelector('.slick-prev').click()}/>
                        <div className="w-full">
                            <Slider {...settings}>
                                {currentExperts.map((expert, index) => (
                                        <div key={index} className=" px-2 flex py-5 items-center justify-center">
                                            <Tooltip placement="top-start" animation="shift"
                                                     className={`absolute border border-blue-gray-50 bg-white px-4 shadow-xl shadow-black/10`}
                                                     content={
                                                         <div className="w-80">
                                                             <Typography color="blue-gray"
                                                                         className="font-semibold text-base font-poppins text-black">
                                                                 {expert.idExpert.nomExpert} {expert.idExpert.prenomExpert}
                                                             </Typography>
                                                             {expert.details && expert.details["Service Titles"] && (
                                                                 <Typography variant="small" color="blue-gray" className="font-normal text-black opacity-80">
                                                                     <span className="text-md font-bold text-teal-900">Services : </span>
                                                                     {expert.details["Service Titles"].map((title, index) => (
                                                                         <span key={index}> {title} |</span>
                                                                     ))}
                                                                 </Typography>
                                                             )}
                                                             {expert.details && expert.details["Certificat Titles"] && (
                                                                 <Typography variant="small" color="blue-gray" className="font-normal text-black opacity-80">
                                                                     <span className="text-md font-bold text-teal-900">Certificats : </span>
                                                                     {expert.details["Certificat Titles"].map((title, index) => (
                                                                         <span key={index}> {title} |</span>
                                                                     ))}
                                                                 </Typography>
                                                             )}
                                                             {expert.details && expert.details["Competence Titles"] && (
                                                                 <Typography variant="small" color="blue-gray" className="font-normal text-black opacity-80">
                                                                     <span className="text-md font-bold text-teal-900">Compétences : </span>
                                                                     {expert.details["Competence Titles"].map((title, index) => (
                                                                         <span key={index}> {title} |</span>
                                                                     ))}
                                                                 </Typography>
                                                             )}
                                                             {expert.details && expert.details["Formation Titles"] && (
                                                                 <Typography variant="small" color="blue-gray" className="font-normal text-black opacity-80">
                                                                     <span className="text-md font-bold text-teal-900">Formations : </span>
                                                                     {expert.details["Formation Titles"].map((title, index) => (
                                                                         <span key={index}> {title} |</span>
                                                                     ))}
                                                                 </Typography>
                                                             )}
                                                             {expert.details && expert.details["Experience Titles"] && (
                                                                 <Typography variant="small" color="blue-gray" className="font-normal text-black opacity-80">
                                                                     <span className="text-md font-bold text-teal-900">Expériences : </span>
                                                                     {expert.details["Experience Titles"].map((title, index) => (
                                                                         <span key={index}> {title} |</span>
                                                                     ))}
                                                                 </Typography>
                                                             )}
                                                             {/*{expert.services && (
                                                                 <Typography
                                                                     variant="small"
                                                                     color="blue-gray"
                                                                     className="font-normal text-black opacity-80"
                                                                 >

                                                         <span
                                                             className={"text-md font-bold text-teal-900"}>Services : </span>
                                                                     {expert.services.map((service, index) => (
                                                                         <span
                                                                             key={index}> {service.idService.nomService} |</span>
                                                                     ))}
                                                                 </Typography>
                                                             )}
                                                             {expert.certificats && (
                                                                 <Typography
                                                                     variant="small"
                                                                     color="blue-gray"
                                                                     className="font-normal text-black opacity-80"
                                                                 >

                                                                     <span
                                                                         className={"text-md font-bold text-teal-900"}>Certificats : </span>
                                                                     {expert.certificats.map((certificat, index) => (
                                                                         <span
                                                                             key={index}> {certificat.certificat.titreCertificat} |</span>
                                                                     ))}
                                                                 </Typography>
                                                             )}
                                                             {expert.competences && (
                                                                 <Typography
                                                                     variant="small"
                                                                     color="blue-gray"
                                                                     className="font-normal text-black opacity-80"
                                                                 >

                                                                     <span
                                                                         className={"text-md font-bold text-teal-900"}>Compétences : </span>
                                                                     {expert.competences.map((competence, index) => (
                                                                         <span
                                                                             key={index}> {competence.competence.titreCompetence} |</span>
                                                                     ))}
                                                                 </Typography>
                                                             )}
                                                             {expert.formations && (
                                                                 <Typography
                                                                     variant="small"
                                                                     color="blue-gray"
                                                                     className="font-normal text-black opacity-80"
                                                                 >
                                                                     <span
                                                                         className={"text-md font-bold text-teal-900"}>Formations : </span>
                                                                     {expert.formations.map((formation, index) => (
                                                                         <span
                                                                             key={index}> {formation.formation.titreFormation} |</span>
                                                                     ))}
                                                                 </Typography>
                                                             )}
                                                             {expert.experiences && (
                                                                 <Typography
                                                                     variant="small"
                                                                     color="blue-gray"
                                                                     className="font-normal text-black opacity-80"
                                                                 >
                                                                     <span
                                                                         className={"text-md font-bold text-teal-900"}>Expériences : </span>
                                                                     {expert.experiences.map((experience, index) => (
                                                                         <span
                                                                             key={index}>{experience.titreExperience}</span>
                                                                     ))}
                                                                 </Typography>
                                                             )}*/}
                                                         </div>
                                                     }
                                            >
                                                <Link to={`/expertSelected/${expert.idExpert.idExpert}`}>
                                                    <ExpertCard expert={expert.idExpert}/>
                                                </Link>


                                            </Tooltip>

                                            {/* Infobulle qui s'affiche au clic */}
                                            <Tooltip
                                                placement="top-start"
                                                animation="shift"
                                                className={`absolute border md:hidden border-blue-gray-50 bg-white px-4 shadow-xl shadow-black/10 ${tooltipVisible ? 'visible' : 'invisible'}`}
                                                content={(
                                                    <div className="w-80">
                                                        <Typography color="blue-gray"
                                                                    className="font-semibold text-base font-poppins text-black">
                                                            {expert.idExpert.nomExpert} {expert.idExpert.prenomExpert}
                                                        </Typography>
                                                        {expert.details && expert.details["Service Titles"] && (
                                                            <Typography variant="small" color="blue-gray" className="font-normal text-black opacity-80">
                                                                <span className="text-md font-bold text-teal-900">Services : </span>
                                                                {expert.details["Service Titles"].map((title, index) => (
                                                                    <span key={index}> {title} |</span>
                                                                ))}
                                                            </Typography>
                                                        )}
                                                        {expert.details && expert.details["Certificat Titles"] && (
                                                            <Typography variant="small" color="blue-gray" className="font-normal text-black opacity-80">
                                                                <span className="text-md font-bold text-teal-900">Certificats : </span>
                                                                {expert.details["Certificat Titles"].map((title, index) => (
                                                                    <span key={index}> {title} |</span>
                                                                ))}
                                                            </Typography>
                                                        )}
                                                        {expert.details && expert.details["Competence Titles"] && (
                                                            <Typography variant="small" color="blue-gray" className="font-normal text-black opacity-80">
                                                                <span className="text-md font-bold text-teal-900">Compétences : </span>
                                                                {expert.details["Competence Titles"].map((title, index) => (
                                                                    <span key={index}> {title} |</span>
                                                                ))}
                                                            </Typography>
                                                        )}
                                                        {expert.details && expert.details["Formation Titles"] && (
                                                            <Typography variant="small" color="blue-gray" className="font-normal text-black opacity-80">
                                                                <span className="text-md font-bold text-teal-900">Formations : </span>
                                                                {expert.details["Formation Titles"].map((title, index) => (
                                                                    <span key={index}> {title} |</span>
                                                                ))}
                                                            </Typography>
                                                        )}
                                                        {expert.details && expert.details["Experience Titles"] && (
                                                            <Typography variant="small" color="blue-gray" className="font-normal text-black opacity-80">
                                                                <span className="text-md font-bold text-teal-900">Expériences : </span>
                                                                {expert.details["Experience Titles"].map((title, index) => (
                                                                    <span key={index}> {title} |</span>
                                                                ))}
                                                            </Typography>
                                                        )}
                                                        {/*{expert.services && (
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-black opacity-80"
                                                            >

                                                        <span
                                                            className={"text-md font-bold text-teal-900"}>Services : </span>
                                                                {expert.services.map((service, index) => (
                                                                    <span
                                                                        key={index}> {service.idService.nomService} |</span>
                                                                ))}
                                                            </Typography>
                                                        )}
                                                        {expert.certificats && (
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-black opacity-80"
                                                            >

                                                    <span
                                                        className={"text-md font-bold text-teal-900"}>Certificats : </span>
                                                                {expert.certificats.map((certificat, index) => (
                                                                    <span
                                                                        key={index}> {certificat.certificat.titreCertificat} |</span>
                                                                ))}
                                                            </Typography>
                                                        )}
                                                        {expert.competences && (
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-black opacity-80"
                                                            >

                                                    <span
                                                        className={"text-md font-bold text-teal-900"}>Compétences : </span>
                                                                {expert.competences.map((competence, index) => (
                                                                    <span
                                                                        key={index}> {competence.competence.titreCompetence} |</span>
                                                                ))}
                                                            </Typography>
                                                        )}
                                                        {expert.formations && (
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-black opacity-80"
                                                            >
                                                        <span
                                                            className={"text-md font-bold text-teal-900"}>Formations : </span>
                                                                {expert.formations.map((formation, index) => (
                                                                    <span
                                                                        key={index}> {formation.formation.titreFormation} |</span>
                                                                ))}
                                                            </Typography>
                                                        )}
                                                        {expert.experiences && (
                                                            <Typography
                                                                variant="small"
                                                                color="blue-gray"
                                                                className="font-normal text-black opacity-80"
                                                            >
                                                    <span
                                                        className={"text-md font-bold text-teal-900"}>Expériences : </span>
                                                                {expert.experiences.map((experience, index) => (
                                                                    <span
                                                                        key={index}>{experience.titreExperience}</span>
                                                                ))}
                                                            </Typography>
                                                        )}*/}
                                                    </div>
                                                )}
                                            >
                                                {/* Icône ou élément déclencheur de l'infobulle */}
                                                <div className=" md:hidden flex justify-center items-center relative">
                                                    <button
                                                        className=" flex items-center text-white font-semibold text-xs py-1 px-2 rounded-full"
                                                        onClick={toggleTooltip}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 24 24"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            className="h-6 w-6 md:hidden text-gray-500"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                                                            />
                                                        </svg>
                                                        <span
                                                            className="text-black font-poppins text-md ml-1"> Plus d'informations </span>

                                                    </button>

                                                </div>
                                            </Tooltip>


                                        </div>

                                ))}
                            </Slider>
                        </div>
                        <NextArrow onClick={() => document.querySelector('.slick-next').click()}/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default NosExperts;
