import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const DomaineDropdown = ({ domaineServices, selectedDomaine, setSelectedDomaine }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [selectAll, setSelectAll] = useState(false);

    const handleCheckboxChange = (idDomaine) => {
        setSelectedDomaine((prevSelected) =>
            prevSelected.includes(idDomaine)
                ? prevSelected.filter((id) => id !== idDomaine)
                : [...prevSelected, idDomaine]
        );
    };

    const handleSelectAllChange = () => {
        if (selectAll) {
            setSelectedDomaine([]);
        } else {
            setSelectedDomaine(domaineServices.map((domaine) => domaine.idDomaine));
        }
        setSelectAll(!selectAll);
    };

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (selectedDomaine.length === domaineServices.length) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    }, [selectedDomaine, domaineServices]);

    useEffect(() => {
        // Sync selectedDomaine state with checkboxes in dropdown
        setSelectAll(selectedDomaine.length === domaineServices.length);
    }, [domaineServices]);

    return (
        <div className="relative z-50 justify-center font-poppins items-center" ref={dropdownRef}>
            <button
                id="dropdownCheckboxButton"
                onClick={handleDropdownToggle}
                className="text-white bg-green-600 w-60 font-poppins hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-500 font-medium rounded-full text-sm px-5 py-2.5 text-center inline-flex items-center"
                type="button"
            >
                {selectedDomaine.length > 0
                    ? `Selected: ${selectedDomaine.length}`
                    : 'Choisissez un domaine'}
                <FontAwesomeIcon
                    icon={faChevronDown}
                    className="absolute right-4 top-1/2 transform -translate-y-1/2 text-white pointer-events-none"
                    size="lg"
                />
            </button>
            {isDropdownOpen && (
                <div
                    id="dropdownDefaultCheckbox"
                    className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-full bg-gray-700 divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 max-h-52 overflow-y-auto z-50"
                >
                    <ul className="p-3 space-y-3 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownCheckboxButton">
                        <li>
                            <div className="flex items-center">
                                <input
                                    id="checkbox-select-all"
                                    type="checkbox"
                                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                    checked={selectAll}
                                    onChange={handleSelectAllChange}
                                />
                                <label htmlFor="checkbox-select-all" className="ms-2 w-full text-left text-sm font-medium text-gray-300">
                                    Sélectionner tout
                                </label>
                            </div>
                        </li>
                        {domaineServices.map((domaine) => (
                            <li key={domaine.idDomaine}>
                                <div className="flex items-center">
                                    <input
                                        id={`checkbox-item-${domaine.idDomaine}`}
                                        type="checkbox"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                                        value={domaine.idDomaine}
                                        checked={selectedDomaine.includes(domaine.idDomaine)}
                                        onChange={() => handleCheckboxChange(domaine.idDomaine)}
                                    />
                                    <label htmlFor={`checkbox-item-${domaine.idDomaine}`} className="ms-2 w-full text-left text-sm font-medium text-gray-300">
                                        {domaine.nomDomaine}
                                    </label>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default DomaineDropdown;
