import React from 'react';
import photoSorry from "../../images/icon_Rejected.webp";
import Confirmation from '../Confirmation';

export default function SorryPage() {
    return (
        <Confirmation
            title="Désolé"
            primaryMessage="Votre demande de consultation n'a pas pu être enregistrée."
            secondaryMessage="Veuillez réessayer plus tard ou contacter notre support."
            tertiaryMessage="Nous nous excusons pour le désagrément."
            imageSrc={photoSorry}
            altText="icon Error"
        />
    );
}