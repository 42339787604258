import { XCircleIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import apiClient from "../Services/apiService";
import photoSorry from "../images/icon_Rejected.webp";
import Confirmation from "./Confirmation";
/*import { baseUrl } from "../constants/baseUrl";*/
const PaymentFailed = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const message = searchParams.get("message")
    const transactionId = searchParams.get("transaction_id")
    const code = searchParams.get("code")
    useEffect(()=>{
        async function addMotif(){
            await apiClient.post('/paiement/addMotif?message=' + message + '&code='+ code +'&transaction_id=' + transactionId)
        }
        addMotif()
    },[])
    return (
        <>
            {/*<div className="bg-white dark:bg-primary py-6 sm:py-8 lg:py-12 font-poppins">
                <div className="mx-auto max-w-screen-lg px-4 md:px-8">
                    <div className="mb-4 sm:mb-8 lg:mb-10 flex flex-col items-center justify-center">
                        <XCircleIcon className="h-20 w-20 mb-4 text-red-500"/>
                        {message ?
                            <h2 className="mb-4 text-center text-2xl font-bold text-black  md:mb-6 lg:text-3xl">{message}</h2> :
                            <h2 className="mb-4 text-center text-2xl font-bold text-gray-700  md:mb-6 lg:text-3xl">Paiement
                                                                                                                   échoué</h2>}
                        <p className="mb-4 text-center text-md max-w-sm font-normal text-gray-600  md:mb-4 lg:text-lg">Votre
                                                                                                                       commande
                                                                                                                       n'a
                                                                                                                       pas
                                                                                                                       pu
                                                                                                                       être
                                                                                                                       confirmée.
                                                                                                                       Veuillez
                                                                                                                       vérifier
                                                                                                                       vos
                                                                                                                       informations
                                                                                                                       de
                                                                                                                       paiement
                                                                                                                       et
                                                                                                                       réessayer
                                                                                                                       ultérieurement.</p>
                    </div>
                    <div className="flex flex-col items-center gap-4">
                        <Link to="/"
                              className="font-poppins inline-block rounded-lg bg-third px-8 py-3 text-center text-sm font-medium text-gray-700 outline-none ring-white transition duration-100  md:text-base">Retour</Link>
                    </div>
                </div>
            </div>*/}
            <Confirmation
                title={message ? <h2>{message}</h2> : <h2>Paiement échoué</h2>}
                primaryMessage="Votre paiement n'a pas pu être traité."
                secondaryMessage="Veuillez vérifier vos informations de paiement et réessayer ultérieurement."
                tertiaryMessage="Si le problème persiste, n'hésitez pas à contacter notre support client pour obtenir de l'aide."
                imageSrc={photoSorry}
                altText="icon Error"
            />
        </>

    );
}

export default PaymentFailed;