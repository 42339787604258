import React from 'react';
import photoCongrat from "../../images/icon_Congratulation.webp";
import Confirmation from '../Confirmation';

export default function CongratulationPageContact() {
    return (

        <Confirmation
            title="Merci de nous avoir contacté !"
            primaryMessage="Nous avons bien reçu votre message."
            secondaryMessage="Notre équipe vous répondra dans les plus brefs délais."
            tertiaryMessage="En attendant, n'hésitez pas à consulter notre FAQ pour trouver des réponses à vos questions."
            imageSrc={photoCongrat}
            altText="icon Validate"
        />
    );
}