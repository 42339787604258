import apiClient from './apiService';


export const getServiceDetails = async (serviceId) => {
    try {
        const response = await apiClient.get(`/services/list/${serviceId}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
//react query