import React, {useState} from 'react';
import {Field, Form, Formik} from 'formik';
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import * as Yup from 'yup';
import logoHbm from "../images/Logo_Les-Experts_FNL-10.webp";
import {toast} from "react-toastify";

const PasswordResetSchema = Yup.object().shape({
    email: Yup.string().email('Adresse email invalide').required('L\'email est obligatoire'),
});

const PasswordResetPage = () => {
    const [responseStatus, setResponseStatus] = useState('');
    const navigate = useNavigate()

    const handleSubmit = async (values, { setSubmitting }) => {
        setResponseStatus('');
        try {
            const response = await fetch(`${window._env_.REACT_APP_BACKEND_URL}/api/v1/auth/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: values.email }),
            });

            if (response.ok) {
                //setResponseStatus('Lien de réinitialisation envoyé avec succès');
                toast.success("Lien de réinitialisation envoyé avec succès")
                navigate("/congratulationUrlReset");
            } else if (response.status === 404) {
                //setResponseStatus('Utilisateur non trouvé');
                toast.error("Utilisateur non trouvé")
            } else {
                //setResponseStatus('Erreur lors de l\'envoi du lien');
                toast.error('Erreur lors de l\'envoi du lien');
            }
        } catch (error) {
            //setResponseStatus('Erreur de connexion au serveur');
            toast.error('Erreur de connexion au serveur');
        }
        setSubmitting(false);
    };

    return (
        <>
            <div className="bg-gray-50 dark:bg-primary font-poppins">
                <div className="min-h-screen flex flex-col items-center justify-center py-6 px-4">
                    <div className="max-w-md w-full">
                        <a href="/"><img
                            src={logoHbm} alt="logo" className='w-40 mb-8 mx-auto block'/>
                        </a>
                        <div className="p-8 rounded-2xl bg-white shadow">
                            <h2 className="text-center text-xl font-bold mb-6">Réinitialiser votre mot de passe</h2>
                            <Formik
                                initialValues={{email: ''}}
                                validationSchema={PasswordResetSchema}
                                onSubmit={handleSubmit}
                            >
                                {({errors, touched, isSubmitting}) => (
                                    <Form className="space-y-4">
                                        {responseStatus && (
                                            <div className="text-green-500 pt-2 text-sm">{responseStatus}</div>
                                        )}
                                        <div>
                                            <label className="text-gray-800 text-sm mb-2 block">Email</label>
                                            <Field
                                                name="email"
                                                type="email"
                                                required
                                                className="w-full text-gray-800 text-sm border border-gray-300 px-4 py-3 rounded-md outline-third"
                                                placeholder="Entrez votre email"
                                            />
                                            <div className="h-6">
                                                {errors.email && touched.email ? (
                                                    <div className="text-red-500 pt-2 text-xs">{errors.email}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="!mt-8">
                                            <button
                                                type="submit"
                                                disabled={isSubmitting}
                                                className="w-full py-3 px-4 text-sm tracking-wide rounded-lg text-white bg-green-700 hover:bg-green-800 focus:outline-none"
                                            >
                                                Envoyer le lien de réinitialisation
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PasswordResetPage;
