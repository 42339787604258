import {CheckCircleIcon} from "@heroicons/react/24/outline";
import {Link} from "react-router-dom";
import photoCongrat from "../images/icon_Congratulation.webp";
import Confirmation from "./Confirmation";
import React from "react";
/*import { baseUrl } from "../constants/baseUrl";*/
const PaymentSuccessful = () => {
    return (
        <>
            {/*<div className="bg-white dark:bg-primary py-6 sm:py-8 lg:py-12 font-poppins">
                <div className="mx-auto max-w-screen-lg px-4 md:px-8">
                    <div className="mb-4 sm:mb-8 lg:mb-10 flex flex-col items-center justify-center">
                        <CheckCircleIcon className="h-20 w-20 mb-4 text-third gr"/>
                        <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">Paiement
                                                                                                              réussi</h2>
                        <p className="mb-4 text-center text-lg max-w-sm font-normal text-gray-700 md:mb-6 lg:text-xl">Votre commande a été confirmée et est en cours de traitement. Merci d'avoir fait vos achats chez nous !</p>
                    </div>
                    <div className="flex flex-col items-center gap-4">
                        <Link to="/"
                              className="font-poppins inline-block rounded-lg bg-third px-8 py-3 text-center text-sm font-normal text-gray-700 outline-none ring-indigo-300 transition duration-100  md:text-base">Retour</Link>
                    </div>
                </div>
            </div>*/}
            <Confirmation
                title="Paiement réussi"
                primaryMessage="Votre paiement a été effectué avec succès. "
                secondaryMessage="Merci pour votre confiance et d'avoir choisi notre plateforme pour votre consultation."
                tertiaryMessage="Votre expert a été informé de votre réservation. Veuillez consulter votre boîte mail pour les détails."
                imageSrc={photoCongrat}
                altText="icon Validate"
            />
        </>

    );
}
export default PaymentSuccessful;