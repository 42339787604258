import { useCallback, useEffect, useState } from "react";
const Timer = ({dateDebut}) => {
    const [countDownTime, setCountDownTIme] = useState({
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
    });
    function getDateInTimezone(timezone) {
        const now = new Date();
    
        // Create an Intl.DateTimeFormat object to get the offset
        const formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });
    
        const parts = formatter.formatToParts(now);
        const year = parts.find(p => p.type === 'year').value;
        const month = parts.find(p => p.type === 'month').value;
        const day = parts.find(p => p.type === 'day').value;
        const hour = parts.find(p => p.type === 'hour').value;
        const minute = parts.find(p => p.type === 'minute').value;
        const second = parts.find(p => p.type === 'second').value;
    
        // Construct the date string
        const dateString = `${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
    
        // Return as a Date object
        return new Date(dateString);
    }
    const getTimeDifference = (countDownTime) => {
        const currentTime = getDateInTimezone(window._env_.REACT_APP_TIMEZONE).getTime();
        const timeDiffrence = countDownTime - currentTime;
        let days =
            Math.floor(timeDiffrence / (24 * 60 * 60 * 1000)) >= 10
                ? Math.floor(timeDiffrence / (24 * 60 * 60 * 1000))
                : `0${Math.floor(timeDiffrence / (24 * 60 * 60 * 1000))}`;
        const hours =
            Math.floor((timeDiffrence % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)) >=
                10
                ? Math.floor((timeDiffrence % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60))
                : `0${Math.floor(
                    (timeDiffrence % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
                )}`;
        const minutes =
            Math.floor((timeDiffrence % (60 * 60 * 1000)) / (1000 * 60)) >= 10
                ? Math.floor((timeDiffrence % (60 * 60 * 1000)) / (1000 * 60))
                : `0${Math.floor((timeDiffrence % (60 * 60 * 1000)) / (1000 * 60))}`;
        const seconds =
            Math.floor((timeDiffrence % (60 * 1000)) / 1000) >= 10
                ? Math.floor((timeDiffrence % (60 * 1000)) / 1000)
                : `0${Math.floor((timeDiffrence % (60 * 1000)) / 1000)}`;
        if (timeDiffrence < 0) {
            setCountDownTIme({
                days: "00",
                hours: "00",
                minutes: "00",
                seconds: "00",
            });
            clearInterval();
        } else {
            setCountDownTIme({
                days: days,
                hours: hours,
                minutes: minutes,
                seconds: seconds,
            });
        }
    };
    const startCountDown = useCallback(() => {
        const countDownDate = new Date(dateDebut);
        setInterval(() => {
            getTimeDifference(countDownDate.getTime());
        }, 1000);
    }, []);
    useEffect(() => {
        startCountDown();
    }, [startCountDown]);
    return (
        <div className="bg-transparent">
            <div className="flex flex-col items-center justify-center w-full h-full gap-8 sm:gap-16">
                <div className="flex justify-center gap-3 sm:gap-8">
                    <div className="flex flex-col gap-5 relative">
                        <div className=" flex justify-between items-center rounded-lg">
                            <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-[#191A24]"></div>
                            <span className="lg:text-5xl sm:text-2xl text-xl font-semibold text-white">
                                {countDownTime?.days}
                            </span>
                            <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-[#191A24]"></div>
                        </div>
                        <span className="text-white/85 text-xs sm:text-2xl text-center capitalize">
                            {countDownTime?.days == 1 ? "Jour" : "Jour"}
                        </span>
                    </div>
                    <div className="flex flex-col gap-5 relative">
                        <div className=" flex justify-between items-center rounded-lg">
                            <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-[#191A24]"></div>
                            <span className="lg:text-5xl sm:text-2xl text-xl font-semibold text-white">
                                {countDownTime?.hours}
                            </span>
                            <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-[#191A24]"></div>
                        </div>
                        <span className="text-white/85 text-xs sm:text-2xl text-center font-medium">
                            {countDownTime?.hours == 1 ? "Heure" : "Heures"}
                        </span>
                    </div>
                    <div className="flex flex-col gap-5 relative">
                        <div className=" flex justify-between items-center rounded-lg">
                            <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-[#191A24]"></div>
                            <span className="lg:text-5xl sm:text-2xl text-xl font-semibold text-white">
                                {countDownTime?.minutes}
                            </span>
                            <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-[#191A24]"></div>
                        </div>
                        <span className="text-white/85 text-xs sm:text-2xl text-center capitalize">
                            {countDownTime?.minutes == 1 ? "Minute" : "Minutes"}
                        </span>
                    </div>
                    <div className="flex flex-col gap-5 relative">
                        <div className=" flex justify-between items-center rounded-lg">
                            <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 !-left-[6px] rounded-full bg-[#191A24]"></div>
                            <span className="lg:text-5xl sm:text-2xl text-xl font-semibold text-white">
                                {countDownTime?.seconds}
                            </span>
                            <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-[#191A24]"></div>
                        </div>
                        <span className="text-white/85 text-xs sm:text-2xl text-center capitalize">
                            {countDownTime?.seconds == 1 ? "Second" : "Seconds"}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Timer;
