import React from 'react';
import photoCongrat from "../../images/icon_Congratulation.webp";
import Confirmation from '../Confirmation';

export default function ValidateDemandePage() {
    return (
        <Confirmation
            title="Félicitations !!"
            primaryMessage="Vous avez validé la demande de consultation avec succès."
            secondaryMessage="Merci pour votre coopération."
            imageSrc={photoCongrat}
            altText="icon Validate"
        />
    );
}