import React, {useEffect, useState} from "react";
import '../styles/NosDomains.css';
import {getDomaineService} from "../../Services/DomaineService";
import apiClient from "../../Services/apiService";

export default function NosDomaines({ setSelectedDomaine }) {
    const [selectedDomains, setSelectedDomains] = useState([]);
    const [domaineServices, setDomaineServices] = useState([]);
    const [showDomainesDropdown, setShowDomainesDropdown] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                //const domainesService = await getDomaineService(); // Appel à votre fonction d'API pour récupérer les données
                const domainesService = await apiClient.get('/domaines/listDomaine'); // Appel à votre fonction d'API pour récupérer les données
                setDomaineServices(domainesService.data);
            } catch (err) {
                console.error("Erreur lors de la récupération des services de l'expert :", err);
                // Gestion des erreurs si nécessaire
            }
        };

        fetchData();
    }, []);

    const handleDomaineClick = (idDomaine) => {
        /*setSelectedDomaine(prevSelected => {
            if (prevSelected.includes(idDomaine)) {
                // If already selected, remove it
                return prevSelected.filter(id => id !== idDomaine);
            } else {
                // Otherwise, add it
                return [...prevSelected, idDomaine];
            }
        });*/
        setSelectedDomaine([idDomaine]);
        document.getElementById("services").scrollIntoView({ behavior: "smooth" }); // Rediriger vers la section des services
    };

    const handleDomainChange = (event) => {
        const {value, checked} = event.target;
        if (checked) {
            setSelectedDomains(prevSelected => [...prevSelected, value]);
        } else {
            setSelectedDomains(prevSelected => prevSelected.filter(domain => domain !== value));
        }
    };

    return (
        <div className="text-center md:w-3/4 pb-10 px-5 justify-center items-center mx-auto">
            <h2 className="title-domaine font-poppins text-3xl font-bold my-5 text-gray-700">Nos domaines </h2>
            <p className="mt-5 mx-auto max-w-xl font-poppins text-md font-normal my-5 text-center text-gray-700">
                Explorez nos domaines d'expertise pour trouver l'accompagnement dont vous avez besoin.
            </p>
            <section>
                <div className="mx-auto w-full max-w-4xl ">
                    <ul className="grid gap-5 grid-cols-2 md:grid-cols-5 md:gap-0">
                        {domaineServices.map((domaine, index) => (
                        <li
                            className="flex flex-col items-center font-poppins gap-2 pb-6 md:border-gray-300">
                            <div key={index} onClick={() => handleDomaineClick(domaine.idDomaine)} className={"cursor-pointer"} >
                                <img
                                    //src="img_service.PNG"
                                    src={`data:image/jpeg;base64,${domaine.iconDomaine}`}
                                    alt=""
                                    className="inline-block w-32 h-32 rounded-full object-cover"
                                />
                                <p className="text-md text-gray-800 font-medium">{domaine.nomDomaine}</p>
                            </div>
                        </li>
                        ))}
                    </ul>
                </div>
            </section>

            {/*<div className={"flex flex-col md:flex-row  md:space-x-10 justify-center items-center"}>

                <div className="flex flex-col md:flex-row p-2 justify-center  md:items-start md:gap-8 md:mt-5">

                    <div className="flex flex-col items-center md:items-start md:gap-1">
                        {domaineServices.map((domaine, index) => (
                            index % 2 === 0 && (
                                <div key={index} onClick={() => handleDomaineClick(domaine.idDomaine)}
                                     className="flex justify-center items-center mb-5 cursor-pointer">

                                    <img src={`img_service.PNG`} alt={`image-${index}`} className="w-28 mx-2 h-28"/>
                                    <div
                                        //className={`w-64 ${domaine.descriptionDomaine.length > 40 ? 'h-32' : 'h-24'} domaines-item-left p-5 flex text-left items-center rounded-3xl ml-auto`}
                                        className={`w-64 h-24 domaines-item-left p-5 flex text-left items-center rounded-3xl ml-auto`}
                                    >
                                        <div className="text-left font-poppins">
                                            <strong className="block text-md font-bold text-black ml-2">
                                                {domaine.nomDomaine}
                                            </strong>
                                            <p className="text-xs font-medium text-gray-600 ml-2">
                                                {domaine.descriptionDomaine}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        ))}
                    </div>

                    <div className="flex flex-col items-center md:items-end md:gap-1">
                        {domaineServices.map((domaine, index) => (
                            index % 2 !== 0 && (
                                <div key={index} onClick={() => handleDomaineClick(domaine.idDomaine)}
                                     className="flex justify-center items-center mb-5 cursor-pointer">
                                    <div
                                        //className={`w-64 ${domaine.descriptionDomaine.length > 40 ? 'h-32' : 'h-24'} domaines-item-right p-5 flex text-right items-center mr-auto rounded-3xl`}
                                        className={`w-64 h-24 domaines-item-right p-5 flex text-right items-center mr-auto rounded-3xl`}
                                    >
                                        <div className="text-left font-poppins">
                                            <strong className="block text-md font-bold text-black mr-2">
                                                {domaine.nomDomaine}
                                            </strong>
                                            <p className="text-xs font-medium text-gray-600 mr-2">
                                                {domaine.descriptionDomaine}
                                            </p>
                                        </div>
                                    </div>
                                    <img src={`img_service.PNG`} alt={`image-${index}`} className="w-28 mx-2 h-28"/>
                                </div>
                            )
                        ))}
                    </div>
                </div>

            </div>*/}
            {/*            <section>
                 Container
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
                     Title
                    <h2 className="items-center text-center text-3xl font-bold md:text-5xl">
                        Make every step user-centric
                    </h2>
                    <p className="mx-auto mb-8 mt-4 max-w-lg text-center text-sm text-gray-500 sm:text-base md:mb-12">
                        Lorem ipsum dolor sit amet consectetur adipiscing elit ut
                        aliquam,purus sit amet luctus magna fringilla urna
                    </p>
                     List
                    <ul className="grid gap-5 sm:grid-cols-2 md:grid-cols-4 md:gap-0">
                        <li className="flex flex-col items-center gap-6 md:border-r md:px-10 md:py-4 p-8 md:border-gray-300">
                            <img
                                src="img_service.PNG"
                                alt=""
                                className="inline-block w-28 h-28 rounded-full object-cover"
                            />
                            <p className="text-xl font-semibold">Support</p>
                            <p className="text-center text-sm text-gray-500">
                                Lorem ipsum dolor sit amet consectetur adipiscing elit ut aliquam,
                                purus sit.
                            </p>
                        </li>
                        <li className="flex flex-col items-center gap-6 md:border-r md:px-10 md:py-4 p-8 md:border-gray-300">
                            <img
                                src="img_service.PNG"
                                alt=""
                                className="inline-block w-28 h-28 rounded-full object-cover"
                            />
                            <p className="text-xl font-semibold">Organise</p>
                            <p className="text-center text-sm text-gray-500">
                                Lorem ipsum dolor sit amet consectetur adipiscing elit ut aliquam,
                                purus sit.
                            </p>
                        </li>
                        <li className="flex flex-col items-center gap-6 md:border-r md:px-10 md:py-4 p-8 md:border-gray-300">
                            <img
                                src="img_service.PNG"
                                alt=""
                                className="inline-block w-28 h-28 rounded-full object-cover"
                            />
                            <p className="text-xl font-semibold">Flexibility</p>
                            <p className="text-center text-sm text-gray-500">
                                Lorem ipsum dolor sit amet consectetur adipiscing elit ut aliquam,
                                purus sit.
                            </p>
                        </li>
                        <li className="flex flex-col items-center gap-6 md:border-r md:px-10 md:py-4 p-8 md:border-gray-300">
                            <img
                                src="img_service.PNG"
                                alt=""
                                className="inline-block w-28 h-28 rounded-full object-cover"
                            />
                            <p className="text-xl font-semibold">Speed</p>
                            <p className="text-center text-sm text-gray-500">
                                Lorem ipsum dolor sit amet consectetur adipiscing elit ut aliquam,
                                purus sit.
                            </p>
                        </li>
                    </ul>
                </div>
            </section>*/}
        </div>

    )
}