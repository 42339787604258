import apiClient from './apiService';

export const getExpertsByService = async (serviceId,page, size) => {
    try {
        const response = await apiClient.get(`/experts/service/${serviceId}/experts?page=${page}&size=${size}`);
        return response.data;
    } catch (error) {
        throw error;
    }
};
//react query