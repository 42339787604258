// RequireAuth.js
import React from 'react';
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated';
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom';
const RequireAuthentification = ({children}) => {
    const isAuthenticated = useIsAuthenticated()
    const location = useLocation()
    //console.log('Require : ' , location.pathname)
    const navigate = useNavigate();
    return (
        isAuthenticated ? (
            {...children}
        ) : (
            //navigate('/login', { state: { id: 7, color: 'green' } })
            <Navigate to={"/login"} state={{from:  location}} />
        )
    );
};
export default RequireAuthentification;